.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  min-height: 100vh !important;
  z-index: 100;
  padding: 10px 20px;
  box-shadow: 0px 1px 5px $sort-green-shade2;
  width: 250px;
  list-style: none;
  overflow-y: auto;
  .logo-section {
    background-color: $sort-green;
    margin-bottom: 15px;
    height: 85px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    .side-width {
      width: 120px;
      height:34px;
    }
    .collapse-icon {
      display: none;
    }
    .logged-user-role {
      float: right;
      margin: 5px 0;
      color: #fff;
      font-weight: 600;
      font-size: 15px;
      width:40%;
    }
    .client-role-section {
      float: right;
      margin: 5px 0;
      color: #fff;
      font-weight: 600;
      font-size: 15px;
    }
  }
  .nav-item {
    padding: 15px 0px 15px 20px;
    a {
      text-align: left;
      vertical-align: top;
      color: $sort-gray;
      text-decoration: none;
      &:hover{
        color: #77BF4B !important;
      }
    }
    .navigation-category {
      color: $sort-green;
      font-weight: 600;
      font-size: 15px;
      text-transform: capitalize;
    }
    &.nav-parent {
      padding: 10px 24px;
      p.sidebar-menu {
        font-size: 14px;
        font-weight: 700;
        cursor: pointer;
        color: $sort-gray;
        white-space: nowrap;
      }
      ul {
        padding-left: 0.5rem;
        li.nav-sub {
          list-style: none;
          padding: 5px 0;
          a.active {
            color: $sort-green;
          }
        }
      }
    }
    &.nav-parent-settings {
      padding: 10px 35px;
      p.sidebar-menu {
        font-size: 14px;
        font-weight: 700;
        cursor: pointer;
        color: $sort-gray;
      }
      ul {
        padding-left: 1.7rem;
        li.nav-sub {
          list-style: none;
          padding: 5px 0;
          a.active {
            color: $sort-green;
          }
        }
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and down) */
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .sidebar {
    width: 200px !important;
    .logo-section {
      .client-role-section,.logged-user-role {
        float: right;
        margin: 0;
        color: #fff;
        font-weight: 600;
        font-size: 15px;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }
    }
    .nav-item {
      padding: 15px 10px 15px 20px;
      a {
        font-size: 13px;
      }
      .side-width {
            width: auto;
            height: 28px;
        &.collapsed-view-logo {
          width: 80% !important;
        }
      }
      .collapse-btn {
        &.show {
          position: absolute;
          right: -10px;
          top: 15px;
        }
      }
      .collapse-icon {
        display: inline-block !important;
        width: 20px;
        height: 20px;
      }
      &.nav-parent.collapsed-nav-parent {
        padding: 0 20px;
      }
    }
    &.collapsed-view {
      width: 60px !important;
    }
  }
}
.main-side-link.active {
  color: $sort-green !important;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .sidebar {
    display: none;
    &.show-sidebar {
      display: block !important;
      .collapse-icon.collapse-icon {
        display: none !important;
      }
      .nav-item {
        padding: 15px 10px 15px 20px;
        a {
          font-size: 13px;
        }
        .side-width {
          width: 80% !important;
          max-width: 100px !important;
        }
      }
    }
  }
  .sidebar-mobile-modal .fade:not(.in) .modal-dialog {
    -webkit-transform: translate3d(-25%, 0, 0);
    transform: translate3d(-25%, 0, 0);
  }
  .sidebar-mobile-modal.fade:not(.in) .modal-dialog {
    -webkit-transform: translate3d(-2%, -25%, 0);
    transform: translate3d(0%, -25%, 0);
    margin: 0;
  }
}

// samsun galaxy S20
@media only screen and (min-width: 360px) and (max-width: 767px) {
  .sidebar {
    display: none;
    &.show-sidebar {
      display: block !important;
      .collapse-icon.collapse-icon {
        display: none !important;
      }
      .nav-item {
        padding: 15px 10px 15px 20px;
        a {
          font-size: 13px;
        }
        .side-width {
          width: 80% !important;
          max-width: 100px !important;
        }
      }
    }
  }
  .sidebar-mobile-modal .fade:not(.in) .modal-dialog {
    -webkit-transform: translate3d(-25%, 0, 0);
    transform: translate3d(-25%, 0, 0);
  }
  .sidebar-mobile-modal.fade:not(.in) .modal-dialog {
    -webkit-transform: translate3d(-2%, -25%, 0);
    transform: translate3d(-2%, -25%, 0);
    margin: 0;
  }
}
//iphone 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .sidebar {
    display: none;
    &.show-sidebar {
      display: block !important;
      .collapse-icon.collapse-icon {
        display: none !important;
      }
      .nav-item {
        padding: 15px 10px 15px 20px;
        a {
          font-size: 13px;
        }
        .side-width {
          width: 80% !important;
          max-width: 100px !important;
        }
      }
    }
  }
  .sidebar-mobile-modal .fade:not(.in) .modal-dialog {
    -webkit-transform: translate3d(-25%, 0, 0);
    transform: translate3d(-25%, 0, 0);
  }
  .sidebar-mobile-modal.fade:not(.in) .modal-dialog {
    -webkit-transform: translate3d(-2%, -25%, 0);
    transform: translate3d(0%, -25%, 0);
    margin: 0;
  }
}