.page-container-top-margin {
  margin-top: 100px;
}

.account-page-left-container {
  .carousel-wrapper {
    height: 400px;
    width: 400px;
    padding: 10px;
    border: 1px solid $sort-gray-shade4;
    border-radius: 3px;
    margin: 10px;
    background-color: $primary-gray;
  }
}

.account-page-right-container {
  background-color: $white;
}

//layout styles
#layout-wrapper {
  position: relative;
  min-height: 100vh;
  background: linear-gradient(0deg, #f9f9f9, #f9f9f9), #3e66fb;
}

//update accounts
.update-account-overview {
  border-radius: 10px;
  box-shadow: 0px 1px 3px #c7c9cc;

  .card-body {
    padding: 2rem 3rem;

    .acc-overview-lable {
      color: #4088c5;
      font-weight: 600;
    }
  }
}

.sort-form-field {
  position: relative;
}

.toggle-switch {
  position: relative;
  width: 90px;
  text-align: left;
  height: 36px;

  &.custom-switch {
    .label1 {
      display: block;
      overflow: hidden;
      cursor: pointer;
      border: 0 solid #e0e0e0;
      border-radius: 32px;
      width: 70px;

      &.customized-toggle {
        width: 55px;

        .inner {
          display: block;
          width: 200%;
          margin-left: -100%;
          transition: margin 0.3s ease-in 0s;

          &::after,
          &::before {
            float: left;
            width: 50%;
            height: 25px;
            padding: 0;
            line-height: 32px;
            color: #fff;
            font-weight: bold;
            box-sizing: border-box;
          }
        }

        .inner:before {
          content: "" !important;
          font-style: normal;
          font-weight: bold;
          padding-left: 10px;
          background-color: $sort-green;
          color: #fff;
          font-size: 14px;
        }

        .inner:after {
          content: "";
          font-style: normal;
          font-weight: bold;
          padding-right: 10px;
          color: #9a9fa6;
          text-align: right;
          font-size: 14px;
          background: #e7e8eb;
          box-sizing: border-box;
        }

        .switch {
          bottom: 11px;
          width: 22px;
          right: 35px;
          //       .checkbox:checked + .label1 .switch {
          //     right: 35px;
          // }
        }
      }
    }
  }
}

.checkbox {
  display: none;
}

.label1 {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #e0e0e0;
  border-radius: 32px;
  width: 70px;
}

.inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.inner:before,
.inner:after {
  float: left;
  width: 50%;
  height: 32px;
  padding: 0;
  line-height: 32px;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
}

.inner:before {
  content: "Yes";
  font-style: normal;
  font-weight: bold;
  padding-left: 10px;
  background-color: $sort-green;
  color: #fff;
  font-size: 14px;
}

.inner:after {
  content: "No";
  font-style: normal;
  font-weight: bold;
  padding-right: 10px;
  color: #9a9fa6;
  text-align: right;
  font-size: 14px;
  background: #e7e8eb;
  box-sizing: border-box;
}

.switch {
  display: block;
  width: 30px;
  margin: 2px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 4px;
  right: 55px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.checkbox:checked+.label1 .inner {
  margin-left: 0;
}

.checkbox:checked+.label1.customized-toggle .inner {
  margin-left: 0;
}

.checkbox:checked+.label1 .switch {
  right: 20px;
}

// Active Toggle

.toggle-switch3 {
  position: relative;
  width: 40px;
  display: inline-block;
  text-align: left;
  height: 20px;
}

.checkbox3 {
  display: none;
}

.label3 {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
}

.inner3 {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}

.inner3:before,
.inner3:after {
  float: left;
  width: 50%;
  height: 20px;
  box-sizing: border-box;
}

.inner3:before {
  content: "";
  background-color: #77bf4b;
}

.inner3:after {
  content: "";
  background-color: #bbb;
}

.switch3 {
  display: block;
  width: 17px;
  height: 17px !important;
  margin: 2px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}

.checkbox3:checked+.label3 .inner3 {
  margin-left: 0;
}

.checkbox3:checked+.label3 .switch3 {
  right: 0px;
}

//table
.table-caption {
  background: linear-gradient(0deg, #77bf4b, #77bf4b), #3e66fb;
  border-radius: 8px 8px 0px 0px;
  height: 52px;
  padding: 15px;
}

.score-table-caption-left {
  text-align: left;
  color: #fff;
}

.score-table-caption-right {
  float: right;
  margin-right: 5px;
  margin-top: 5px;
}

.score-header-class {
  background: #daebcc;
  border-radius: 0px;
  border-top: none;

  th {
    color: #727070;
    padding: 10px;
  }
}

.tableBody {
  background: linear-gradient(0deg, #f9f9f9, #f9f9f9), #3e66fb;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  //color: #f9f9f9;
  border-top: 3px solid #77bf4b;

  thead {
    border: 1px solid #ddd;
  }

  &.table> :not(:first-child) {
    border-top: 1px solid transparent;
  }

  tbody {
    tr {
      td {
        padding: 15px 10px;
        color: #727070;
        font-size: 15px;
        font-weight: 600;
      }
    }
  }

  &.client-view-score-table {
    th:first-child {
      width: 10%;
    }
  }
}

.table-wrapper-client-account {
  background: linear-gradient(0deg, #f9f9f9, #f9f9f9), #3e66fb;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  width: 95%;
  padding-bottom: 20px;

  .finance-settings-table {
    caption {
      p {
        font-size: 15px;
        font-weight: 600;
      }
    }
    &.table:not(caption)>*>* {
      background-color: #fff !important;
      // padding: 0.75rem 0.5rem;
    }
    border-top: 3px solid #77bf4b;

    thead {
      border:1px solid #ddd;
      th {
        padding: 0.75rem;
        color: $sort-green;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        border: none;
        &:nth-child(1), &:nth-child(2),  &:nth-child(3), &:nth-child(4){
          width: 20%;
        }
        &:nth-child(5), &:nth-child(6){
          width: 10%;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 0 0.75rem;
          color: #727070;
          font-size: 13px;
          font-weight: 600;
          text-decoration: none !important;
          
          &:nth-child(5), &:nth-child(6){
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }
  }
  .repayment-settings-table{
    caption {
      p {
        font-size: 15px;
        font-weight: 600;
      }
    }
    &.table:not(caption)>*>* {
      background-color: #fff !important;
      // padding: 0.75rem 0.5rem;
    }
    border-top: 3px solid #77bf4b;

    thead {
      border:1px solid #ddd;
      th {
        padding: 0.75rem;
        color: $sort-green;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        border: none;
        &:first-child, &:nth-child(2){
          width:40%;
        }
        &:nth-child(3), &:nth-child(4){
          width:10%;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 0 0.75rem;
          color: #727070;
          font-size: 13px;
          font-weight: 600;
          text-decoration: none !important;
          
          &:nth-child(3), &:nth-child(4){
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }
  }
}

caption {
  caption-side: top;
}

//forms
.sort-form-wrapper {
  position: relative;

  .form-group.sort-form-group {
    input {
      position: relative;
    }

    .errorImg {
      position: absolute;
      right: -20px;
      top: 14px;
    }
  }

  .inline-form-error {
    &.display-error {}
  }
}

.client-account-header {
  color: #fff;
}

//expand collapse table
.vl {
  border-left: 2px solid #77bf4b;
}

.row-hidden {
  visibility: collapse;
}

.row-visible {
  visibility: visible;
}

.arrow {
  border: solid #77bf4b;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 5px;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.react-bootstrap-table .row-expansion-style {
  padding: 0;

  tbody {
    td {
      &:first-child {
        width: 25%;
      }

      &:nth-child(2) {
        width: 25%;
      }

      &:nth-child(3) {
        width: 20%;
      }

      &:nth-child(4) {
        width: 14%;
      }

      &:nth-child(5),
      &:nth-child(6) {
        width: 8%;
      }
    }
  }
}

//update client modal
.modal-content {
  // background: #ffffff !important;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12) !important;
  border-radius: 8px !important;
}

.update-header {
  background: linear-gradient(0deg, #77bf4b, #77bf4b), #3e66fb !important;
  border-radius: 8px 8px 0px 0px !important;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 140%;
  color: #ffffff;
  display: inline !important;
  position: relative;
}

.update-modal-body {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  color: #55636b;
}

.close-icon {
  content: "x";
  cursor: pointer;
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1.425px solid #ffffff;
  font-size: 20px;
  margin: 8px 0 10px 20px;
  right: 5%;
  top: 20%;
}

.update-form-label {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #55636b;
}

.update-select-option {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 140%;
  color: #9a9fa6;
}

.update-save {
  background: linear-gradient(0deg, #77bf4b, #77bf4b), #3e66fb;
  border-radius: 5px;
  text-transform: none;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  color: #ffffff;
}

.update-cancel {
  border-radius: 5px;
  border: 1px solid #77bf4b;
  background: white !important;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  color: #75c044;
  text-transform: none;
}

//login
.background-layout {
  min-height: 100vh;
  position: relative;
  background: linear-gradient(0deg, #77bf4b, #77bf4b), #3e66fb;
}

.box-layout {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5% 0;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .box-layout {
    padding: 20% 0;
  }
}

@media only screen and (min-width: 360px) and (max-width: 767px) {
  .box-layout {
    padding: 20% 0;
  }
}

.img-layout {
  margin-bottom: 25px;
}

.form-layout {
  background: #ffffff;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 50px;
}

@media only screen and (min-width: 600px) {
  .form-layout {
    padding: 50px;
    width: 416px;
  }
}

.form-head {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 95%;
  text-align: center;
  color: #55636b !important;
  margin-bottom: 25px;
}

.form-group-wrapper {
  position: relative;

  .login-input-invalid {
    border-color: $error;
  }

  .login-input::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 19px;
    color: #c7c9cc;
  }

  .login-error {
    position: absolute;
    right: -20px;
    top: 15px;
  }
}

.login-button-layout {
  text-align: center;
}

.button-login {
  font-size: 15px;
  line-height: 21px;
  background: $sort-green;
  border-radius: 5px;
  width: 196px;
  height: 41px;
  margin-top: 30px;
  text-transform: none;
}

.errorStyle {
  color: $error;
}

$datepicker__font-size: 16.2148px;
$datepicker__selected-color: #77bf4b;
$datepicker__text-color: #55636b;
$datepicker__header-color: #77bf4b;
$datepicker__border-radius: 5.01634px;
$datepicker__background-color: #ffffff;

.react-datepicker {
  font-size: 12px;
  border: none !important;
  box-shadow: 0px 4.01307px 4.01307px rgba(0, 0, 0, 0.25),
    0px 8.02614px 16.0523px rgba(0, 0, 0, 0.12) !important;
}

.react-datepicker__header {
  font-style: normal !important;
  font-weight: bold !important;
}

.createDate .react-datepicker__day-name {
  width: 42px !important;
  font-size: 13px !important;
}

.createDate .react-datepicker__day {
  width: 40px !important;
  font-size: 13px !important;
}

.createDate .react-datepicker__time-name {
  width: 49px !important;
}

.viewDate .react-datepicker__day-name {
  width: 25px !important;
  font-size: 13px !important;
}

.viewDate .react-datepicker__day {
  width: 25px !important;
  font-size: 13px !important;
}

.viewDate .react-datepicker__time-name {
  width: 19px !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: 13px !important;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  font-size: 13px !important;
}

.react-datepicker__day--outside-month {
  color: rgb(231, 226, 226) !important;
  // pointer-events: none;
  // visibility: hidden;
}

.react-datepicker__month-container {
  height: 285px;
}

.react-datepicker__input-container {
  svg {
    color: $sort-green;
  }
}

.login-button-layout {
  text-align: center;
}

.button-login {
  font-size: 15px;
  line-height: 21px;
  background: $sort-green !important;
  border-radius: 5px;
  width: 196px;
  height: 41px;
  margin-top: 30px;
  text-transform: none;
}

.errorStyle {
  color: $error;
}

$datepicker__font-size: 16.2148px;
$datepicker__selected-color: #77bf4b;
$datepicker__text-color: #55636b;
$datepicker__header-color: #77bf4b;
$datepicker__border-radius: 5.01634px;
$datepicker__background-color: #ffffff;

.react-datepicker {
  font-size: 12px;
  border: none !important;
  box-shadow: 0px 4.01307px 4.01307px rgba(0, 0, 0, 0.25),
    0px 8.02614px 16.0523px rgba(0, 0, 0, 0.12) !important;
}

.react-datepicker__header {
  font-style: normal !important;
  font-weight: bold !important;
}

.createDate .react-datepicker__day-name {
  width: 42px !important;
  font-size: 13px !important;
}

.createDate .react-datepicker__day {
  width: 40px !important;
  font-size: 13px !important;
}

.createDate .react-datepicker__time-name {
  width: 49px !important;
}

.viewDate .react-datepicker__day-name {
  width: 25px !important;
  font-size: 13px !important;
}

.viewDate .react-datepicker__day {
  width: 25px !important;
  font-size: 13px !important;
}

.viewDate .react-datepicker__time-name {
  width: 19px !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: 13px !important;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  font-size: 13px !important;
}

.react-datepicker__day--outside-month {
  color: rgb(231, 226, 226) !important;
  // pointer-events: none;
  // visibility: hidden;
}

.react-datepicker__month-container {
  height: 285px;
}

.react-datepicker__input-container {
  svg {
    color: $sort-green;
  }
}

@import "~react-datepicker/src/stylesheets/datepicker.scss";
@import "~video-react/styles/scss/video-react";

.report-footer {
  margin-top: 100px;
  color: #eff6e8;
  background: linear-gradient(0deg, #eff6e8, #eff6e8), #3e66fb;
  box-shadow: 0px -5px 11px 1px rgba(0, 0, 0, 0.09),
    0px 8px 16px rgba(0, 0, 0, 0.12);
}

.report-header-audit {
  background: linear-gradient(0deg, #77bf4b, #77bf4b), #3e66fb !important;
  border-radius: 8px !important;
  // font-weight: bold;
  font-size: 15px;
  color: #ffffff;

  &.errorField {
    border: 1px solid $error;
    color: $error;
  }
}

.dropzone {
  text-align: center;
  padding: 30px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
  cursor: pointer;
  margin-bottom: 20px;
}

.selected-file-wrapper {
  text-align: center;
}

.selected-file {
  color: #000;
  font-weight: bold;
}

.image-text {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 140%;
  color: #55636b;
  margin-bottom: 0;
}

.audit-findings-header,
.opportSumm-header {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: #55636b;
}

// Audit Section

.upload-box {
  text-align: center;
  padding: 30px;
  background-color: #fafafa;
  color: #bdbdbd;
  cursor: pointer;
  margin-bottom: 20px;
}

.upload-text {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 95%;
  color: #55636b;
}

.audit-header-class {
  box-shadow: 1px 4px 4px 1px #c7c9cc;
}

.report-view {
  .card {
    border: none !important;

    .card-body {
      background-color: #f9f9f9;

      .container-fluid {
        padding: 0;

        .audit-findings-header-right {
          float: right;
        }
      }
    }
  }
}

//audit findings
.upload-section-header {
  background-color: $white;
  padding: 10px 0;
  margin: 0 auto;
}

.click-browse-btn {
  border: none;
  background: transparent;
  color: #1786be;
}

.card-video.card {
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin: 5px;

  .card-img-top.card-image-video {
    width: 75%;
    height: 15vw;
    object-fit: contain;
    margin: 30px auto;
    margin-bottom: 10px;
  }

  .card-image-defualt {
    width: 200px;
    height: 200px;
    object-fit: contain;
    margin: 30px auto;
    margin-bottom: 10px;
    border: 1px solid #eee;
  }

  .card-body {
    background-color: $white;
    border-radius: 10px;
    padding: 0 40px;

    .card-title.card-title {
      color: $sort-green;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 15px;
    }

    .card-sub-title {
      color: #727070;
      font-weight: 600;
      font-size: 13px;
    }

    .card-bottom-link {
      text-align: right;
      margin-bottom: 30px;

      svg {
        color: #77bf4b;
      }
    }

    .card-body1.card-text {
      color: #999797;
      max-height: 115px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;

      &.showMore {
        overflow: auto;
        text-overflow: unset;
        max-height: 100%;

        display:inline-block .button {
          display: inline-block;
        }
      }
    }

    .sort-btn-link.card-link {
      background-color: #fff !important;
      border: none;
      text-decoration: none;
      padding: 0.375rem 0 !important;
    }
  }

  &.grid-view {
    box-shadow: none;

    .upload-img {
      width: 100px;
      object-fit: contain;
      height: 100px;
    }

    .default-img {
      width: 100px;
      border: 1px solid #eee;
      height: 100px;
    }

    .grid-filename {
      color: $sort-green;
      font-size: 16px;
      font-weight: 600;
    }

    .grid-note {
      color: #727070;
      font-weight: 600;
      font-size: 13px;
    }

    .grid-info {
      color: #999797;
      font-size: 13px;
      //max-height: 60px;
      // overflow: hidden;
      text-overflow: ellipsis;

      &.showMore {
        overflow: auto;
        text-overflow: unset;
        max-height: 100%;
        display: inline-block;

        .button {
          display: inline-block;
        }
      }
    }

    .sort-btn-link.card-link {
      background-color: #fff !important;
      border: none;
      text-decoration: none;
      padding: 0.375rem 0 !important;
    }

    .grid-right-btn {
      img {
        margin: 0 10px;
      }

      svg {
        color: $sort-green;
        margin: 0 10px;
        cursor: pointer;
      }
    }
  }
}

.card-doc {
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;

  .card-body-doc.card-body {
    background-color: #fff !important;

    .card-image-doc {
      width: 30%;
      object-fit: contain;
      margin-bottom: 10px;
    }
  }

  .card-footer {
    padding: 15px;
    background-color: linear-gradient(0deg, #e7e8eb, #e7e8eb), #3e66fb;
    color: $sort-green;
    font-weight: 600;
    font-size: 15px;
    border-radius: 0px 0px 5px 5px;
  }
}

.preview-img {
  &:hover {
    cursor: pointer;
  }

  &.disabled-click {
    pointer-events: none;

    &:hover {
      cursor: auto;
    }
  }
}

.reupload-body {
  padding: 10px 30px;

  h4 {
    color: $sort-green;
    font-weight: 600;
  }

  p {
    color: $sort-gray;
    font-size: 15px;
    font-weight: 600;
  }
}

.custom-fieldset {
  position: relative;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;

  h1 {
    position: absolute;
    top: 0;
    font-size: 13px;
    line-height: 1;
    margin: -18px 0 0;
    /* half of font-size */
    background: #fff;
    padding: 10px;
    font-weight: 600;
  }
}

.css-1insrsq-control {
  padding: 5px !important;
  background-color: #f9f9f9 !important;
  border-color: #c7c9cc !important;
  font-size: 0.9375rem !important;
}

.reupload-form-list {
  background-color: #f3f3f3;
  border-radius: 0;
  border-left: none;
  border-right: none;
  display: flex;
  align-items: center;

  .failed-file-name {
    color: #55636b;
    font-weight: 600;
    font-size: 16px;
    display: inline-grid;
    margin-left: 20px;
    vertical-align: middle;

    .file-error {
      font-size: 13px;
    }
  }
}

.dropzone-editor {
  text-align: center;
  padding: 30px;
  border: 1.5px dashed #9a9fa6;
  background-color: #f9f9f9;
  color: #bdbdbd;
  cursor: pointer;
  margin-bottom: 20px;
  border-radius: 5px;
  max-width: 100%;
}

.card-photo.card {
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin: 5px;

  .card-img-top.card-image-photo {
    width: 75%;
    height: 15vw;
    object-fit: contain;
    margin: 30px auto;
    margin-bottom: 10px;
  }
}

.screen-size {
  width: 280px;
  height: 190px;
}

.down-image {
  //position: absolute;
  z-index: 1;
  // max-width: 200px;
  // height: 190px;
  // object-fit: fill;
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.override-image {
  position: absolute;
  left: 25px;
  z-index: 2;
}

// My Dashboard
.dashboard-header {
  font-family: "focoBold";
  font-style: normal;
  font-size: 35px;
  line-height: 95%;
  color: #55636b;
}

.dashboard-heading {
  font-family: "focoBold";
  font-style: normal;
  font-size: 15px;
  line-height: 95%;
}

.dashboard-card {
  border: none !important;
}

.dropzone-image-inside {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.dropzone-uploading-editor {
  text-align: center;
  padding: 30px;
  border: 1.5px dashed #9a9fa6;
  background-color: #f9f9f9;
  color: #bdbdbd;
  margin-bottom: 20px;
  border-radius: 5px;
  max-width: 100%;
}

.uploading-sentence {
  font-family: "focoBold";
  font-style: normal;
  font-size: 18.8069px;
  line-height: 140%;
  color: #4088c5;
}

// Report Section
.save-modal-title {
  text-align: center;
  margin-top: 30;
  font-weight: bold;
}

.disable-fields {
  background: linear-gradient(0deg, #e7e8eb, #e7e8eb), #3e66fb;
}

.selected-image-name {
  color: #9a9fa6;
  font-family: "focoBold";
  text-align: left;
}

//Client view
.client-view .guide-tab {
  margin: 0 13%;
}

.green-header.client-report-header {
  font-size: 20px;
  color: #52b316;
}

.client-view-guide-card {
  .card-title.h5 {
    font-size: 18px;
    font-weight: 600;
    color: #55636b;
  }

  .card-text {
    color: #9a9fa6;
    font-size: 15px;
  }
}

.client-view-opportunity,
.client-view-audit {
  .filter-section {
    .form-check {
      display: inline-block;
      min-height: 1.5rem;
      padding-left: 1.5em;
      margin-bottom: 0.125rem;
      margin-right: 2rem;
    }
  }
  .table-oportunity-header {
    background-color: $white;

    th {
      padding: 10px;
      color: $sort-green;
      font-size: 16px;

      &:nth-child(2),
      &:nth-child(9) {
        width: 8%;
      }

      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8),
      &:nth-child(5) {
        width: 11%;
      }

      &:first-child {
        width: 14%;
      }
      &:nth-child(10) {
        width: 4%;

        .form-check {
          min-height: 0;

          .form-check-input {
            border: 1px solid $sort-green;
            padding: 5px;
          }
        }
      }
    }
  }

  .table-opoortunity-header-mobile {
    display: none;
  }

  .opp-accordion {
    .card {
      border: none;
      background: linear-gradient(0deg, #f9f9f9, #f9f9f9), #3e66fb;

      .card-header {
        border-bottom: 1px solid $sort-green;
        color: $sort-green;
        font-weight: 600;
        background: linear-gradient(0deg, #f9f9f9, #f9f9f9), #3e66fb;
        padding: 0.5rem 0;
      }

      .card-body {
        padding: 1rem 0;

        .green-lable {
          color: $sort-green !important;
          text-transform: capitalize;
        }

        .doc-section {
          color: #3e66fb;
        }

        .oppo-report-row {
          color: #55636b;

          &:nth-child(odd) {
            background-color: #fff;

            .card-body {
              background: #fff;
              font-size: 11px;
            }

            .card-header {
              background: #fff !important;
              color: #4088c5;
              font-size: 11px;
            }
          }

          .card-header {
            color: #4088c5;
            font-size: 11px;
          }

          .accordion {
            padding: 0;

            .card .card-header {
              padding: 10px;
            }
          }
        }

        .show-content {
          display: block;
        }

        .hide-content {
          display: block;
        }

        .column-1 {
          width: 14%;
        }
        .column-1-epc{
          width: 16%;
          padding: 10px;
        }
        .column-2-epc{
          width: 9%;
          padding: 10px;
        }
        .column-3-epc{
          width: 12.5%;
          padding: 10px;
        }
        .column-4-epc{
          width: 13%;
          padding: 10px;
        }
        .column-5-epc{
          width: 12%;
          padding: 10px;
        }
        .column-6-epc{
          width: 13%;
          padding: 10px;
        }
        .column-7-epc{
          width: 12%;
          padding: 10px;
        }
        .column-8 {
          cursor: pointer;
        }

        .column-3,
        .column-4,
        .column-7,
        .column-6,
        .column-8,
        .column-5 {
          width: 11%;
        }

        .column-2,
        .column-9 {
          width: 8%;
        }
        .column-10 {
          width: 4%;
          margin:auto;
          &.activate-checkbox {
            .form-check-input {
              border: 1px solid $sort-green;
            }
          }
        }
      }
    }

    &.mobile-data-table {
      display: none;
    }
  }

  .row-total {
    background-color: #eff6e8;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #c2c7be;

    span {
      color: $sort-green;
    }

    .row-total-title {
      width: 20%;
      display: inline-block;
    }
    .row-total-title-epc {
      width: 25%;
      display: inline-block;
    }

    .row-total-details {
      display: inline-block;
      width: 75%;

      .total-detail {
        width: 15%;
        display: inline-block;
      }
      .total-detail-epc {
        width: 17%;
        display: inline-block;
      }
    }

    &.total-mobile {
      display: none;
    }
  }
}

.client-view-audit-mobile {
  display: none;
}

// samsun galaxy S20
@media only screen and (min-width: 360px) and (max-width: 767px) {
  .client-view .guide-tab {
    margin: 0 !important;
  }

  .opp-accordion,
  .audit-accordion {
    display: none !important;
  }

  .opp-accordion.mobile-data-table {
    display: block !important;

    .client-view-card-body {
      .mobile-client-card-title.card-title {
        background: linear-gradient(0deg, #eff6e8, #eff6e8), #3e66fb;
        padding: 5px;

        .activate-checkbox {
          display: inline-block;
        }

        .green-lable {
          display: inline-block;
          font-size: 10px;
        }
      }

      .oppo-report-row.mobile-accordion-data {
        .show-more-section {
          padding: 0;

          .column-1 {
            width: 25%;
            font-size: 10px;
          }
        }

        .card .card-header {
          border: none;
        }

        .column-1 {
          width: 25%;
          font-size: 10px;
          padding: 2px;
        }

        .opp-descktop-view {
          display: none;
        }

        .opp-mobile-view {
          .card-header {
            button {
              float: right;
            }
          }
        }
      }
    }

    .card-header {
      color: $sort-green;
      font-weight: 600;
      background: linear-gradient(0deg, #f9f9f9, #f9f9f9), #3e66fb;
      padding: 0.5rem 0;

      button {
        font-size: 11px;
        width: auto;
      }
    }
  }

  .audit-accordion.mobile-data-table {
    display: block !important;

    .client-view-card-body {
      .mobile-client-card-title.card-title {
        background: linear-gradient(0deg, #eff6e8, #eff6e8), #3e66fb;
        padding: 5px;

        .green-lable {
          display: inline-block;
          font-size: 10px;
        }
      }

      .audit-report-row {
        .column-1 {
          width: 50%;
          font-size: 10px;
        }

        .card .card-header {
          border: none;
        }

        .column-2 {
          width: 25%;
          font-size: 10px;
          padding: 2px;
        }
      }
    }

    .card-header {
      color: $sort-green;
      font-weight: 600;
      background: linear-gradient(0deg, #f9f9f9, #f9f9f9), #3e66fb;
      padding: 0.5rem 0;

      button {
        font-size: 11px;
        width: auto;
      }
    }
  }

  .row-total {
    display: none;

    &.total-mobile {
      display: block !important;

      .row-total-details {
        display: inline-block;
        width: 100%;

        .total-detail {
          width: 25%;
          display: inline-block;

          img {
            width: 10px;
          }

          font-size: 11px;
        }
      }
    }
  }
}

//iphone 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .client-view .guide-tab {
    margin: 0 !important;
  }

  .opp-accordion,
  .audit-accordion {
    display: none !important;
  }

  .opp-accordion.mobile-data-table {
    display: block !important;

    .client-view-card-body {
      .mobile-client-card-title.card-title {
        background: linear-gradient(0deg, #eff6e8, #eff6e8), #3e66fb;
        padding: 5px;

        .activate-checkbox {
          display: inline-block;
        }

        .green-lable {
          display: inline-block;
          font-size: 10px;
        }
      }

      .oppo-report-row.mobile-accordion-data {
        .show-more-section {
          padding: 0;

          .column-1 {
            width: 25%;
            font-size: 10px;
            padding: 2px;
          }
        }

        .card .card-header {
          border: none;
        }

        .column-1 {
          width: 25%;
          font-size: 10px;
        }

        .opp-descktop-view {
          display: none;
        }

        .opp-mobile-view {
          .card-header {
            button {
              float: right;
            }
          }
        }
      }
    }

    .card-header {
      color: $sort-green;
      font-weight: 600;
      background: linear-gradient(0deg, #f9f9f9, #f9f9f9), #3e66fb;
      padding: 0.5rem 0;

      button {
        font-size: 11px;
        width: auto;
      }
    }
  }

  .row-total {
    display: none;

    &.total-mobile {
      display: block !important;

      .row-total-details {
        display: inline-block;
        width: 100%;

        .total-detail {
          width: 25%;
          display: inline-block;

          img {
            width: 10px;
          }

          font-size: 11px;
        }
      }
    }
  }
}

.document-list {
  padding: 0;
  margin: 0;

  li {
    list-style: none;
    padding: 5px 0;
    border-bottom: 1px solid #f5f2f2;
    font-size: 11px;
    text-transform: capitalize;

    span {
      color: #c7c9cc;
    }
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) and (orientation: Portrait) {

  .client-view-opportunity,
  .client-view-audit {
    ul.document-list {
      margin-bottom: 0;
    }
  }
}

/* Medium devices (landscape tablets, 768px and down) */
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {

  .client-view-opportunity,
  .client-view-audit {
    .table-opoortunity-header {
      th {
        padding: 5px;
        color: $sort-green;
        font-size: 8px;
      }
    }

    .table-opoortunity-header-mobile {
      display: none;
    }

    .card-body {

      .column-1,
      .column-2,
      .column-3,
      .column-4,
      .column-5,
      .column-6,
      .column-7,
      .column-8,
      .column-9 {
        font-size: 12px;
        padding: 10px;
      }
    }
  }
}

// samsung galaxy S20
@media only screen and (min-width: 360px) and (max-width: 767px) {

  .client-view-opportunity,
  .client-view-audit {
    .client-opp-btn-section {
      text-align: center !important;
      margin-top: 10px;

      button {
        margin: 0 10px !important;
        font-size: 10px;
      }
    }
  }

  .table-opoortunity-header {
    display: none;
  }

  .table-opoortunity-header-mobile {
    display: block !important;

    th {
      padding: 5px;
      color: $sort-green;
      font-size: 10px;
      vertical-align: middle;

      .form-check {
        min-height: 0;
        font-size: 0.9375rem;
      }
    }
  }

  .client-view-audit-mobile {
    display: block !important;

    .table-auditfinding-header-mobile {
      th {
        padding: 5px;
        color: $sort-green;
        font-size: 10px;
        vertical-align: middle;
        width: 25% !important;

        &:first-child {
          width: 50% !important;
        }
      }
    }

    .audit-accordion.mobile-data-table {
      .card {
        border: none;
        background: linear-gradient(0deg, #f9f9f9, #f9f9f9), #3e66fb;

        .card-body {
          padding: 0;

          .audit-report-row {
            &:nth-child(odd) {
              background-color: #fff;

              .audit-doc-accordion.accordion {
                .card-header {
                  background: #fff !important;
                }

                .card {
                  border: none;
                  background: #fff !important;

                  .editor-class {
                    background: #fff;
                  }
                }

                .nav.nav-tabs {
                  li {
                    button {
                      background-color: #fff !important;
                    }

                    button.nav-link.active {
                      background-color: #fff !important;
                    }
                  }
                }
              }
            }

            &:nth-child(even) {
              background: #f9f9f9;
            }

            .table-data {
              display: flex;
              width: 100%;
              padding: 10px 5px;

              .column-1 {
                width: 50%;
                font-size: 11px;
                color: $sort-green;
                text-transform: capitalize;
              }

              .column-2 {
                width: 25%;
              }
            }

            .audit-doc-accordion.accordion {
              .card-header {
                color: #3e66fb;
              }

              .custom-editor-title.client-report-editor {
                display: none;
              }

              .wrapper-class.rdw-editor-wrapper {
                min-height: auto;
                border: 0.79px solid #e6e8eb;

                .rdw-editor-toolbar.main-toolbar {
                  display: none;
                }
              }

              .lable-gray {
                color: #a39c9c;
                font-weight: 600;
                margin-top: 10px;
              }

              .audit-report-doc-section {
                margin-top: 10px;
                padding: 0;

                .nav.nav-tabs {
                  border: none;
                  margin: 0;

                  li {
                    &:first-child button {
                      width: auto;
                    }

                    button {
                      background-color: #f9f9f9;
                      color: $sort-gray !important;
                      border: none;
                    }

                    button.nav-link.active {
                      background-color: #f9f9f9;
                      color: #77bf4b !important;
                      border: none;
                      border-bottom: 5px solid #77bf4b;
                    }
                  }
                }

                .tab-content {
                  .card {
                    background: #f9f9f9;
                    padding: 10px;
                    border-radius: 0;
                    border-bottom: 1px solid #ddd;

                    &.grid-view .upload-img {
                      width: 50px;
                      object-fit: contain;
                      height: 50px;
                    }

                    &.table-view:nth-child(odd) {
                      background: #f9f9f9;
                      margin-bottom: 0;
                    }

                    &.table-view:nth-child(even) {
                      background: #fff;
                      margin-top: 0;
                    }

                    &.grid-view .grid-right-btn {
                      width: 15px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//iphone 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {

  .client-view-opportunity,
  .client-view-audit {
    .client-opp-btn-section {
      text-align: center !important;
      margin-top: 10px;

      button {
        margin: 0 10px !important;
        font-size: 11px;
      }
    }

    .table-opoortunity-header {
      display: none;
    }

    .table-opoortunity-header-mobile {
      display: block !important;

      th {
        padding: 5px;
        color: $sort-green;
        font-size: 10px;
        vertical-align: middle;

        .form-check {
          min-height: 0;
          font-size: 0.9375rem;
        }
      }
    }

    .client-view-audit-mobile {
      display: block !important;

      .table-auditfinding-header-mobile {
        th {
          padding: 5px;
          color: $sort-green;
          font-size: 10px;
          vertical-align: middle;
          width: 25% !important;

          &:first-child {
            width: 50% !important;
          }
        }
      }

      .audit-accordion.mobile-data-table {
        .card {
          border: none;
          background: linear-gradient(0deg, #f9f9f9, #f9f9f9), #3e66fb;

          .card-body {
            padding: 0;

            .audit-report-row {
              &:nth-child(odd) {
                background-color: #fff;

                .audit-doc-accordion.accordion {
                  .card-header {
                    background: #fff !important;
                  }

                  .card {
                    border: none;
                    background: #fff !important;

                    .editor-class {
                      background: #fff;
                    }
                  }

                  .nav.nav-tabs {
                    li {
                      button {
                        background-color: #fff !important;
                      }

                      button.nav-link.active {
                        background-color: #fff !important;
                      }
                    }
                  }
                }
              }

              &:nth-child(even) {
                background: #f9f9f9;
              }

              .table-data {
                display: flex;
                width: 100%;
                padding: 10px 5px;

                .column-1 {
                  width: 50%;
                  font-size: 11px;
                  color: $sort-green;
                  text-transform: capitalize;
                }

                .column-2 {
                  width: 25%;
                }
              }

              .audit-doc-accordion.accordion {
                .card-header {
                  color: #3e66fb;
                }

                .custom-editor-title.client-report-editor {
                  display: none;
                }

                .wrapper-class.rdw-editor-wrapper {
                  min-height: auto;
                  border: 0.79px solid #e6e8eb;

                  .rdw-editor-toolbar.main-toolbar {
                    display: none;
                  }
                }

                .lable-gray {
                  color: #a39c9c;
                  font-weight: 600;
                  margin-top: 10px;
                }

                .audit-report-doc-section {
                  .nav.nav-tabs {
                    border: none;
                    margin: 0;

                    li {
                      &:first-child button {
                        width: auto;
                      }

                      button {
                        background-color: #f9f9f9;
                        color: $sort-gray !important;
                        border: none;
                      }

                      button.nav-link.active {
                        background-color: #f9f9f9;
                        color: #77bf4b !important;
                        border: none;
                        border-bottom: 5px solid #77bf4b;
                      }
                    }
                  }

                  .tab-content {
                    .card {
                      background: #f9f9f9;
                      padding: 10px;
                      border-radius: 0;
                      border-bottom: 1px solid #ddd;

                      &.grid-view .upload-img {
                        width: 80px;
                        object-fit: contain;
                        height: 80px;
                      }

                      &.table-view:nth-child(odd) {
                        background: #f9f9f9;
                        margin-bottom: 0;
                      }

                      &.table-view:nth-child(even) {
                        background: #fff;
                        margin-top: 0;
                      }

                      &.grid-view .grid-right-btn {
                        width: 15px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

#popover-attractiveness-score {
  .popover-arrow {
    display: none !important;
  }

  .popover-body {
    background: #e3e4e8;
    border-color: transparent;
    padding: 0.5rem;
    font-size: 11px;

    .popover-arrow {
      display: none;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        .badge {
          border-radius: 20px;
          margin-right: 5px;
        }
      }
    }
  }
}

// Guide Section
.score-criteria {
  border: 0.79px solid #9a9fa6;
  border-radius: 8px;
  padding: 15px;
  font-family: "focoLight";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 140%;
  color: #9a9fa6;
}

.sub-head-video {
  font-family: "focoLight";
  font-style: normal;
  font-weight: normal;
  font-size: 16.5169px;
  color: #55636b;
  margin-top: 10px;
}

#client-view-report-tabs-tabpane-1 {
  .custom-editor-title.client-report-editor {
    width: 100%;
    margin: 0 auto;
    background-color: #f9f9f9;
    color: #55636b;
    font-size: 24px;
  }

  .wrapper-class.rdw-editor-wrapper {
    border: none;
    padding: 0;
    min-height: 0;

    .rdw-editor-toolbar {
      &.main-toolbar {
        display: none;
      }
    }

    .rdw-editor-main {
      height: auto;
      color: #55636b;
    }
  }

  .client-report-section-header {
    color: #55636b;
    font-size: 18px;
    font-weight: 600;
  }

  .client-card-style {
    box-shadow: 0px 8.92282px 13.3842px rgba(0, 0, 0, 0.12);
    border-radius: 8.92282px;

    &.payback {
      height: 263px;
    }

    &.line,
    &.project {
      height: 300px;
    }

    .client-dashboard-card {
      background-color: $sort-green;
      color: #fff;
      padding: 1.5rem;
      border-top-left-radius: 8.92282px;
      border-top-right-radius: 8.92282px;

      h4 {
        font-size: 30px;
        font-weight: 600;
      }

      h5 {
        font-size: 18px;
        font-weight: 600;
      }

      h6 {
        font-size: 14px;
      }
    }

    .card-body {
      padding: 2rem;

      .dashboard-card-wrapper {
        display: flex;
        align-items: center;

        .dashboard-card-right {
          padding-left: 18px;
          word-break: break-all;

          .dashboard-card-value {
            color: $sort-green;
            font-size: 20px;
            font-weight: 600;
          }

          .dashboard-card-lable {
            color: #55636b;
            font-size: 13px;
          }
        }
      }
    }
  }

  .editor-img-content {
    img {
      //width: 250px;
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
  }

  .editor-right-section {
    padding-left: 20px;

    .editor-header {
      color: #9a9fa6;
      font-size: 16px;
      font-weight: 600;
    }

    .editor-text {
      color: #9a9fa6;
      font-size: 12px;
    }
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) and (orientation: Portrait) {
  .client-card-style {
    z-index: 0;

    canvas {
      width: auto !important;
    }

    .card-body {
      .section-break {
        margin: 5% 0;
      }
    }
  }

  .editor-img-content {
    img {
      //width: 200px !important;
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
  }
}

@media (min-width: 1025px) {
  .client-card-style {
    &.payback {
      height: 263px;
    }

    &.line,
    &.project {
      height: 300px;
    }
  }

  .tab-carousel-section {
    display: none;
  }
}

@media (min-width: 1366px) {
  .client-card-style {
    .card-body {
      .section-break {
        margin: 19.5% 0 !important;
      }
    }
  }
}

//samsung galaxy tab7 and ipad
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  .tab-carousel-section {
    display: block !important;

    .btn-carousel {
      width: 10px;
      height: 10px;
      background-color: #fff;
      border-radius: 10px;
      border: 1px solid $sort-green;
      margin: 0 3px;
      padding: 0;

      &:active,
      &:focus,
      &.active {
        background-color: $sort-green;
      }
    }

    img {
      margin: 0 10px;
    }
  }
}

// samsung galaxy S20
@media only screen and (min-width: 360px) and (max-width: 767px) {
  .tab-carousel-section {
    display: block !important;
    margin-bottom: 10px;
  }

  #client-view-report-tabs-tabpane-1 {
    .custom-editor-title.client-report-editor {
      font-size: 15px;
    }
  }
}

//iphone 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .tab-carousel-section {
    display: block !important;
    margin-bottom: 10px;
  }

  .custom-editor-title.client-report-editor {
    font-size: 15px;
  }
}

//larger descktop
@media (min-width: 1920px) {
  .dashboard-card-wrapper {
    img {
      width: 115px;
    }
  }

  .client-card-style {
    .card-body {
      .section-break {
        margin: 9.5% 0 !important;
      }
    }
  }

  .client-view-report-list {
    .search-content-wrapper {
      position: relative;

      .search-content {
        position: relative;
        width: 90%;
      }
    }
  }
}

//ipad Air4
@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .client-card-style .card-body .section-break {
    margin: 18% 0 !important;
  }

  .opp-accordion.mobile-data-table {
    display: none;
  }

  .client-view-opportunity,
  .client-view-audit {
    .table-opoortunity-header {
      th {
        padding: 5px !important;
        color: $sort-green;
        font-size: 8px;
      }
    }
  }

  .table-opoortunity-header-mobile {
    display: none;
  }

  .card-body {

    .column-1,
    .column-2,
    .column-3,
    .column-4,
    .column-5,
    .column-6,
    .column-7,
    .column-8,
    .column-9 {
      font-size: 10px;
      padding: 5px;
    }
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .client-card-style {
    z-index: 0;

    .card-body {
      .section-break {
        margin: 19.5% 0;
      }
    }
  }

  .editor-img-content {
    img {
      //width: 220px !important;
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
  }

  .opp-accordion.mobile-data-table {
    display: none;
  }
}

// samsun galaxy S20
@media only screen and (min-width: 360px) and (max-width: 767px) {
  #client-view-report-tabs-tabpane-1 .editor-img-content img {
    //width: 150px !important;
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }

  .client-card-style {
    canvas {
      width: 320px;
    }

    &.saving-projectop {
      canvas {
        width: 310px;
      }
    }
  }
}

//iphone 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  #client-view-report-tabs-tabpane-1 .editor-img-content img {
    //width: 150px !important;
    width: 100%;
    height: 100%;
    object-fit: scale-down;
  }

  .client-card-style {
    canvas {
      width: 320px !important;
    }

    &.saving-projectop {
      canvas {
        width: 310px !important;
      }
    }
  }
}

.row-client-project {
  pointer-events: none;
  border-bottom: 0.5px solid #e3e4e8;
  //border-left: 0.5px solid #e3e4e8;
  //border-right: 0.5px solid #e3e4e8;
}

.row-client-project:nth-of-type(4n + 3) {
  border-left: 0.5px solid #e3e4e8;
  border-right: 0.5px solid #e3e4e8;
}

.client-project-report-row {
  .card {
    border: none !important;
  }

  .card-header {
    color: #4088c5;
    font-size: 11px;
    font-family: "Focolight";
    // border-left: 0.5px solid #e3e4e8;
    // border-right: 0.5px solid #e3e4e8;
    border-radius: none;
  }

  .accordion {
    padding: 0;

    .card .card-header {
      padding: 10px;
    }
  }
}

//client report audit findings
.client-view-audit {
  table {
    th:first-child {
      width: 30% !important;
    }

    th:nth-child(2),
    th:nth-child(3) {
      // width: 10% !important;
    }

    th:last-child {
      width: 50% !important;
    }
  }

  .audit-accordion {
    .card {
      border: none;
      background: linear-gradient(0deg, #f9f9f9, #f9f9f9), #3e66fb;

      .card-header {
        border-bottom: 1px solid $sort-green;
        color: $sort-green;
        font-weight: 600;
        background: linear-gradient(0deg, #f9f9f9, #f9f9f9), #3e66fb;
        padding: 0.5rem 0;
        font-size: 16px;
      }

      .card-body {
        padding: 1rem 0;

        .green-lable {
          color: $sort-green !important;
          text-transform: capitalize;
        }

        .doc-section {
          color: #3e66fb;
        }

        .oppo-report-row {
          color: #55636b;

          &:nth-child(odd) {
            background-color: #fff;

            .card-body {
              background: #fff;
              font-size: 11px;
            }

            .card-header {
              background: #fff !important;
              color: #4088c5;
              font-size: 11px;
            }
          }

          .card-header {
            color: #4088c5;
            font-size: 11px;
          }

          .accordion {
            padding: 0;

            .card .card-header {
              padding: 10px;
            }
          }
        }

        .show-content {
          display: block;
        }

        .hide-content {
          display: block;
        }

        .column-1 {
          width: 30%;
          padding: 10px;
          display: inline-block;
          font-size: 14px;
        }

        .column-4 {
          width: 50%;
          padding: 10px;
        }

        .column-2 {
          width: 10%;
          padding: 10px;
          display: inline-block;
        }
      }
    }

    .audit-report-row {
      &:nth-child(odd) {
        background-color: #fff;

        .card-body {
          background: #fff;
          font-size: 11px;
        }

        .card-header {
          background: #fff !important;
          color: #4088c5;
          font-size: 14px;
          border-bottom: none;
        }

        .accordion.audit-doc-accordion {
          .audit-report-doc-section {
            .nav.nav-tabs {
              li {
                button {
                  background-color: #fff;
                  color: $sort-gray !important;
                  border: none;
                }

                button.nav-link.active {
                  background-color: #fff;
                  color: #77bf4b !important;
                  border: none;
                  border-bottom: 5px solid #77bf4b;
                }
              }
            }

            .tab-content {
              .card {
                background: #fff;
                padding: 10px;
                border-radius: 0;
                border-bottom: 1px solid #ddd;

                &.table-view:nth-child(odd) {
                  background: #fff;
                  margin-bottom: 0;
                }

                &.table-view:nth-child(even) {
                  background: #f9f9f9;
                  margin-top: 0;
                }
              }
            }
          }

          .wrapper-class.rdw-editor-wrapper {
            .editor-class {
              padding: 0 10px;
              background-color: #fff;
              color: #a39c9c;
            }
          }
        }
      }

      .card-header {
        color: #4088c5;
        font-size: 11px;
        position: absolute;
        right: 0;
        top: -45px;
        border-bottom: none;
      }

      .accordion.audit-doc-accordion {
        padding: 0;

        .card .card-header {
          padding: 10px;
        }

        .custom-editor-title {
          display: none;
        }

        .wrapper-class.rdw-editor-wrapper {
          min-height: auto;
          border: 0.79px solid #e6e8eb;

          .rdw-editor-toolbar.main-toolbar {
            display: none;
            visibility: hidden !important;
          }

          .editor-class {
            padding: 0 10px;
            background-color: #f9f9f9;
            color: #a39c9c;
          }
        }

        .lable-gray {
          color: #a39c9c;
          font-weight: 600;
        }

        .audit-report-doc-section {
          .nav.nav-tabs {
            border: none;
            margin: 0;

            li {
              &:first-child button {
                width: auto;
              }

              button {
                background-color: #f9f9f9;
                color: $sort-gray !important;
                border: none;
              }

              button.nav-link.active {
                background-color: #f9f9f9;
                color: #77bf4b !important;
                border: none;
                border-bottom: 5px solid #77bf4b;
              }
            }
          }

          .tab-content {
            .card {
              background: #f9f9f9;
              padding: 10px;
              border-radius: 0;
              border-bottom: 1px solid #ddd;

              &.grid-view .upload-img {
                width: 80px;
                object-fit: contain;
                height: 80px;
              }

              &.table-view:nth-child(odd) {
                background: #f9f9f9;
                margin-bottom: 0;
              }

              &.table-view:nth-child(even) {
                background: #fff;
                margin-top: 0;
              }
            }
          }
        }
      }
    }

    .show-content {
      display: block;
    }

    .hide-content {
      display: block;
    }
  }
}

//client-report-financing
.finance-total-section {
  background: #ffffff;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  border: none;
  margin-bottom: 24px;

  .card-body {
    padding: 0;

    .finance-total-section-img {
      background-color: #b9da9e;
      padding: 10px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;

      img {
        margin: 12px 15px;
      }
    }

    .finance-total-section-text {
      padding: 10px;

      .card-title {
        color: #55636b;
        font-weight: 600;
        font-size: 14px;
      }

      .card-text {
        color: $sort-green;
        font-size: 20px;
        font-weight: 700;
      }
    }
  }
}

.client-card-style.saving-projectop {
  height: 300px;
  border: none;
  box-shadow: 0px 14.1805px 21.2707px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

.financing-option-monthly-repayment-card {
  box-shadow: 0px 14.1805px 21.2707px rgba(0, 0, 0, 0.12);
  border-radius: 8px;

  .card-header {
    background-color: $sort-green;
    color: #fff;
    padding: 15px;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
  }

  .card-body {
    padding: 55px 30px;
    text-align: center;
    color: $sort-green;

    .card-text {
      font-weight: 700;
      font-size: 35px;
    }
  }
}

.report-section-lable {
  color: #55636b;
  font-size: 13px;
  margin: 0;
}

.finacing-options-filter-section {
  .form-check {
    display: inline-block;
    margin-right: 40px;
  }
}

.opportunity-select {
  .css-1s2u09g-control {
    min-height: 40px !important;
  }
}

.finance-select-opp.custom-fieldset {
  margin-top: 4rem;
}

.report-header-image {
  .left-div {
    padding-left: 0;
    height: 260px;
  }

  .right-div {
    padding-right: 0;
    height: 260px;
  }

  .report-image {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    // width: 100%;
    // max-height: 100%;
  }
}

/* Medium devices (landscape tablets, 768px and down) */
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .finacing-options-filter-section {
    .form-check {
      display: inline-block;
      margin-right: 30px;
    }
  }

  .finance-select-opp {
    button {
      font-size: 12px;
    }
  }
}

// samsun galaxy S20
@media only screen and (min-width: 360px) and (max-width: 767px) {
  .report-header-image {
    .left-div {
      padding-left: 10px !important;
      height: 200px;
    }

    .right-div {
      padding-right: 10px !important;
      height: 200px;
    }

    .report-image {
      // width: 100%;
      // max-height: 100%;
      width: 100%;
      height: 100%;
      object-fit: scale-down;
      padding-top: 10px;
    }
  }

  .finance-dropdown-section {
    .btn-section {
      margin-top: 5px;

      button {
        width: 100%;
      }
    }

    .dropdown-section {
      .css-1s2u09g-control {
        max-height: 70px !important;
        min-height: 40px !important;
        overflow-y: auto;
      }
    }
  }
}

//iphone 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .report-header-image {
    .left-div {
      padding-left: 10px !important;
      height: 200px;
    }

    .right-div {
      padding-right: 10px !important;
      height: 200px;
    }

    .report-image {
      // width: 100%;
      // max-height: 100%;
      width: 100%;
      height: 100%;
      object-fit: scale-down;
      padding-top: 10px;
    }
  }

  .finance-dropdown-section {
    .btn-section {
      margin-top: 5px;

      button {
        width: 100%;
      }
    }

    .dropdown-section {
      .css-1s2u09g-control {
        max-height: 70px !important;
        min-height: 40px !important;
        overflow-y: auto;
      }
    }
  }
}

.client-dashboard-company-select,
.client-dashboard-company-select.show {
  .dropdown-toggle {
    width: 100%;
    background-color: transparent !important;
    border-color: #77BF4B !important;
    text-align: start;
    color: #55636b;

    .selected-name {
      display: inline-block;
      width: 95%;

      span {
        font-size: 13px;
        color: #55636b;
        font-weight: 100;
        //display: block;
      }

      .selected-company-name {
        font-weight: 700;
        font-size: 15px;
        width: 280px;
        text-overflow: ellipsis;
      }
    }

    &::after {
      vertical-align: 0.5em !important;
      border-top: 0.4em solid !important;
      //margin-left: 65% !important;
    }

    &:focus {
      box-shadow: 0 0 0 1px $sort-green;
    }
  }

  .dropdown-menu {
    width: 100%;
    z-index: 100000;

    .dropdown-item {
      padding: 0.25rem 1rem;

      span {
        font-size: 13px;
        color: #55636b;
        font-weight: 100;
      }

      ul {
        padding: 0;
        margin: 0;

        li {
          list-style: none;
          font-weight: 700;
          font-size: 15px;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      &:active {
        color: #55636b;
        text-decoration: none;
        background-color: #eff6e8;
      }

      &:hover {
        color: #55636b;
        text-decoration: none;
        background-color: #eff6e8;
      }
    }

    .subsidiary-dropdown-item {
      span {
        font-size: 13px;
        color: #55636b;
        font-weight: 100;
        padding: 0.5rem 1rem;
      }

      .dropdown-item {
        font-weight: 700;
        padding: 0.5rem 1rem;
        font-size: 15px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

.page-content.client-dashboard {
  .add-overlay.show {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(82 79 79 / 50%);
    z-index: 10000;
    overflow-x: hidden;
    overflow-y: auto;
    //cursor: pointer;
  }
}

//samsung galaxy tab7 and ipad
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {

  .client-dashboard-company-select,
  .client-dashboard-company-select.show {
    .dropdown-toggle {
      .selected-name {
        .selected-company-name {
          width: 265px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {

  .client-dashboard-company-select,
  .client-dashboard-company-select.show {
    .dropdown-toggle {
      .selected-name {
        .selected-company-name {
          width: 265px;
        }
      }
    }
  }
}

.permission-denied-page {
  margin-top: 100px;

  .no-permission-card {
    padding: 20px;
    color: #aba3a3;
    border: none;
    font-size: 14px;
    text-align: center;
  }
}

//override styles in table header - benchmark table

.benchmark-table-wrapper {
  table {
    border: 1px solid #eee;
    box-shadow: 0 16px 24px rgba(0, 0, 0, .12);
    border-radius: 8px;
    border-top: 3px solid #77bf4b;

    thead .score-header-benchmark th {
      background-color: #daebcc;
      color: #727070;
      padding: 10px;
    }

    tbody tr td {
      padding: 15px 10px;
      color: #727070;
      font-size: 15px;
      font-weight: 600;
    }
  }

}

//create client table styles override
.client-account-table {
  //color: #f9f9f9;
  border-top: 3px solid #77bf4b;

  thead {
    border: 1px solid #ddd;
    th{
      color: #77bf4b;
    }
    
  }

  &.table> :not(:first-child) {
    border-top: 1px solid transparent;
  }

  tbody {
    tr {
      td {
        color: #727070;
        font-size: 13px;
        font-weight: 600;
        padding: 0 0.5rem;
        vertical-align: middle;
      }
    }
  }
}
.baseline-report-table{
  thead{
    tr > th{
      &:first-child, &:nth-child(2){
        width:30%;
      }
    }
  }
}

.errorField {
  .css-1nybg70-control, .css-13cymwt-control, .css-1nk6zbs-control{
    border: 1px solid $error;
    color: $error;
    border-radius: 5px;
  }
  
}