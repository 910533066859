@font-face
{
    font-family: 'focoBold';
    font-weight: bold;
    font-style: normal;
    src: url('../fonts/Foco/Foco_Bd.ttf') format('truetype');
    font-display: swap;
}
@font-face
{
    font-family: 'focoBoldItalic';
    font-weight: bold;
    font-style: italic;
    src: url('../fonts/Foco/Foco_BdIt.ttf') format('truetype');
    font-display: swap;
}
@font-face
{
    font-family: 'focoBlack';
    font-weight: normal;
    font-style: normal;
    src: url('../fonts/Foco/Foco_Blk.ttf') format('truetype');
    font-display: swap;
}
@font-face
{
    font-family: 'focoBlackitalic';
    font-weight: normal;
    font-style: italic;
    src: url('../fonts/Foco/Foco_BlkIt.ttf') format('truetype');
    font-display: swap;
}
@font-face
{
    font-family: 'focoItalic';
    font-weight: normal;
    font-style: italic;
    src: url('../fonts/Foco/Foco_It.ttf') format('truetype');
    font-display: swap;
}
@font-face
{
    font-family: 'focoLight';
    font-weight: normal;
    font-style: normal;
    src: url('../fonts/Foco/Foco_Lt.ttf') format('truetype');
    font-display: swap;
}
@font-face
{
    font-family: 'focoLightItalic';
    font-weight: normal;
    font-style: italic;
    src: url('../fonts/Foco/Foco_LtIt.ttf') format('truetype');
    font-display: swap;
}
@font-face
{
    font-family: 'focoRegular';
    font-weight: normal;
    font-style: normal;
    src: url('../fonts/Foco/Foco_Rg.ttf') format('truetype');
    font-display: swap;
}