.dashboard-section-lable{
  font-size: 1.25rem;
  color: $sort-green;
  font-weight: 600;
}
.description-area{
  border-radius: 5px;
  border:1px solid rgb(192, 191, 191);
  padding: 10px;
  font-size: 0.95rem;
  color: #C7C9CC;
  &.no-border{
    border: none;
    font-size: 1rem;
  color: #48494a;
  }
}
.dashboard-section-lable-sub{
  color:#55636B;
  font-size: 0.8125rem;
}
.dashboard-section-lable-white{
  font-size: 1.25rem;
  color: $white;
  font-weight: 600;
}
.dashboard-section-lable-sub-white{
  color:$white;
  font-size: 0.8125rem
}
.dashboard-footprint-card{
  background: $white;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  .card-body{
    padding: 0;
    .left-footprint-section{
      padding: 20px 30px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      text-align: center;
      .carbon-footprint-input{
        padding: 10px;
        background: linear-gradient(0deg, #F9F9F9, #F9F9F9), #3E66FB;
        border: 1px solid #C7C9CC;
        box-sizing: border-box;
        border-radius: 10px;
        color: #55636B;
        font-size: 1.875rem;
        font-weight: 600;
        &:focus-visible{
          outline: none;
        }
      }
    }
    .right-footprint-section{
      text-align: center;
      background-color: $sort-green;
      color:#fff;
       border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      padding: 20px 30px;
      .dashboard-section-lable-right{
        padding: 5px;
        font-size: 0.8125rem;
        border-radius: 5px;
        border: 1px solid $white;
      }
      .carbon-footprint-output{
        padding: 10px;
        background: $sort-green;
        border: 1px solid $white;
        box-sizing: border-box;
        border-radius: 10px;
        color: $white;
        font-size: 1.875rem;
        font-weight: 600;
        &.errorField{
          border: 1px solid $error;
          color: $error;
        }
      }
    }
  }
}

.untapped-card-style{
  background: #FFFFFF;
  box-shadow: 0px 15.6852px 23.5278px rgba(0, 0, 0, 0.12);
  border-radius: 12.3458px;
  .untapped-card-header{
    background:$sort-green;
    color: $white;
    padding: 1.25rem 0;
    border-top-left-radius: 12.3458px;
    border-top-right-radius: 12.3458px;
    min-height: 100px;
    h4{
      font-size: 0.9375rem;
      font-weight: 600;
    }
  }
  .untapped-card-sub-header{
    background:#9A9FA6;
    color: $white;
    padding: 0.5rem;
    &.add-border-radius{
      border-bottom-left-radius: 12.3458px;
      border-bottom-right-radius: 12.3458px;
    }
    h4{
      font-size: 1.35rem;
      font-weight: 600;
    }
    h5{
      font-size: 0.8125rem;
    }
  }
  .card-body{
    padding: 1rem 0.5rem;
    h6{
      color: $sort-green;
      font-weight: 600;
      font-size: 0.8125rem;
      text-align: center;
    }
    .untapped-card-wrapper {
        display: flex;
        align-items: center;
        .untapped-card-right {
          padding-left: 8px;
          word-break: break-all;
          .untapped-card-value {
            color: $sort-green;
            font-size: 20px;
            font-weight: 600;
          }
          .untapped-card-lable {
            color: #55636b;
            font-size: 13px;
          }
        }
      }
  }
}

.untapped-toggle-section{
  display: flex;
  align-items: center;
  justify-content:flex-end;
  .sort-form-lable{
    margin:0 20px;
  }
  .toggle-switch{
    width: 70px;
    height: 25px;
    .label1{
      width: 60px;
      .inner:before, .inner:after{
        height: 25px;
        line-height: 25px;
        font-size: 13px;
      }
      .switch{
        width: 24px;
        height: 23px;
        // right: 10px;
        margin: 1px;
        right: 43px;
      }
    }
    .checkbox:checked + .label1 .switch{
      right: 10px;
    }
  }
}
.untapped-mobile-view{
  display: none;
}

.capital-planing-total-section{
  background-color: #E4F1D7;
  border-radius: 10px;
  padding: 10px 15px;
  text-align: center;
  color: #55636B;
}
.client-card-style{
  z-index: 0;
  &.capital{
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    border:none;
  }
  &.forecast{
    height: 500px;
    border: none;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
  }
  &.dashboard-line{
    height: 440px;
    border: none;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.12);
  }
}
.forcast-section-right{
  color:#9AA1A9;
}


// samsung galaxy S20
@media only screen and (min-width: 360px) and (max-width: 767px) {
  .dashboard-footprint-card .card-body{
      .left-footprint-section{
      padding: 10px 15px;
      .carbon-footprint-input{
        font-size: 10px;
        padding: 5px;
      }
    }
    .right-footprint-section {
        padding: 10px 15px;
        .carbon-footprint-output{
        font-size: 10px;
        padding: 5px;
      }
    }
  }
  .untapped-large-view{
    display: none;
  }
  .dashboard-section-lable, .dashboard-section-lable-white{
    font-size: 1rem;
  }
  .untapped-toggle-section{
    .sort-form-lable{
      margin: 0;
      font-size: 0.75rem;
    }
    .toggle-switch{
      width:60px;
      .label1{
      width: 60px;
      .inner:before, .inner:after{
        height: 25px;
        line-height: 25px;
        font-size: 13px;
      }
      .switch{
        width: 24px;
        height: 23px;
        // right: 10px;
        margin: 1px;
        right: 33px;
      }
    }
    .checkbox:checked + .label1 .switch{
      right: 1px;
    }
    }
  }
  .untapped-mobile-view{
    display: block !important;
    .carousel.slide{
      padding: 10px 48px;
      .carousel-indicators{
        bottom: -35px;
        button{
          width:10px;
          height: 10px;
          border:1px solid $sort-green;
          background-color: $white;
          border-radius: 10px;
          &.active{
            background-color: $sort-green;
          }
        }
      }
      .carousel-control-prev, .carousel-control-next{
        color:$sort-green;
        .carousel-control-prev-icon, .carousel-control-next-icon{
          width: 1rem;
          height: 1rem;
        }
      }
      .carousel-inner{
        background: $white;
        .carousel-item{
         padding: 0 10px;
      }
      }
      .carousel-control-prev-icon {
          background-image: url('../../../../../assets/images/icons/client/prev.svg');
      }
      .carousel-control-next-icon {
          background-image: url('../../../../../assets/images/icons/client/next.svg');
      }
      
    }
  }
  .dashboard-header{
    font-size: 25px;
  }
  .forcast-section-right{
    margin-top: 10px;
  }
}

//iphone 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .dashboard-footprint-card .card-body {
      .left-footprint-section{
      padding: 10px 15px;
      .carbon-footprint-input{
        font-size: 10px;
        padding: 5px;
      }
    }
    .right-footprint-section {
        padding: 10px 15px;
        .carbon-footprint-output{
        font-size: 10px;
        padding: 5px;
      }
    }
  }
  .untapped-large-view{
    display: none;
  }
  .dashboard-section-lable{
    font-size: 1rem;
  }
  .untapped-mobile-view{
    display: block !important;
    .carousel.slide{
      padding: 10px 48px;
      .carousel-indicators{
        bottom: -35px;
        button{
          width:15px;
          height: 15px;
          border:1px solid $sort-green;
          background-color: $white;
          border-radius: 15px;
          &.active{
            background-color: $sort-green;
          }
        }
      }
      .carousel-control-prev, .carousel-control-next{
        color:$sort-green;
      }
      .carousel-inner{
        background: $white;
        .carousel-item{
         padding: 0 10px;
      }
      }
      .carousel-control-prev-icon {
          background-image: url('../../../../../assets/images/icons/client/prev.svg');
      }
      .carousel-control-next-icon {
          background-image: url('../../../../../assets/images/icons/client/next.svg');
      }
      
    }
  }
  .dashboard-header{
    font-size: 25px;
  }
  .forcast-section-right{
    margin-top: 10px;
  }
}