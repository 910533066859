//Update Client Dashboard Settings

.setting-right-block {
  font-family: "focoBold";
}

.override-checkbox {
  .form-check-input[type="checkbox"] {
    width: 20px;
    height: 20px;
  }
  .form-check-label {
    margin-top: 4px;
    margin-left: 4px;
  }
}

// Finance Settings
.repayment-primary-btn {
  background-color: $sort-green;
  color: $white;
  border: 1px solid $sort-green;
  max-width: max-content;
  margin-right: 30px;

  &:active {
    background-color: $sort-green;
    border: 1px solid $sort-green;
  }
  &:focus {
    background-color: $sort-green;
    border: 1px solid $sort-green;
    box-shadow: none !important;
  }
  &:hover {
    background-color: #63a837;
    border: 1px solid $sort-green !important;
    box-shadow: none !important;
  }

  &.disable {
    background-color: $sort-gray;
    color: rgb(211, 211, 211);
    border: 1px solid $sort-gray;
  }
}

.finance-table-header {
  color: #fff;
  font-size: 20px !important;
  font-family: "focoBold";
  line-height: 140%;
}

@media (min-width: 576px) {
  .modal-repayment .modal-dialog {
    max-width: 500px;
    margin: 7.75rem auto;
  }
}

// Preview Report

.preview-modal {
  min-height: 100vh;
  background: linear-gradient(0deg, #f9f9f9, #f9f9f9), #3e66fb;
  min-width: 100vw;
  padding-right: 0 !important;
}

.preview-header {
  font-size: 18px;
  font-weight: 600;
  color: #77bf4b;
  margin: 0 5px;
}

.preview-modal .modal-dialog {
  max-width: none;
  margin: 0;
}

.preview-modal .modal-content {
  border: none;
}

.preview-modal .modal-header {
  display: inline;
}
.prev-image-wrapper {
  height: 250px;
  .preview-image {
    width: 100%;
    max-height: 100%;
    object-fit: scale-down;
  }
}
.editor-text-executive {
  margin-top: 7% !important;
}

.preview-tab-section {
  ul.nav {
    padding-right: 0;
  }
  ul.nav-tabs {
    font-size: 16px;
    border-bottom: 5px solid #77bf4b;
    width: 100%;

    li {
      &:first-child {
        margin-left: 25%;
      }
      button {
        color: #55636b !important;
        height: 65px;

        &.nav-link.active {
          background-color: #77bf4b;
          color: #fff !important;
          border: 1px solid #77bf4b;
        }
        &:hover,
        &:focus {
          border-color: #77bf4b;
          isolation: isolate;
        }
      }
    }
  }
  &.preview-view {
    ul.nav-tabs {
      li {
        &:first-child {
          margin-left: 0px !important;
          width: 14%;
          button {
            width: 100%;
          }
        }
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          width: 14%;
          button {
            width: 100%;
          }
        }
        &:nth-child(7) {
          width: 16%;
          button {
            width: 100%;
          }
        }
      }
    }
  }
}

.alert-preview {
  color: #55636b;
  font-size: 13px;
  text-align: center;
}

.preview-modal .modal-footer {
  display: inline !important;
}

.preview-view .guide-tab {
  margin: 0 13%;
}

.preview-view .executive-tab {
  margin: 0 5%;
}

.preview-view .opportunity-tab {
  margin: 0 5%;
}

.preview-view .financing-tab {
  margin: 0 5%;
}

//View Client Business
#tooltip-view-client > .tooltip-inner {
  background-color: #c7c9cc;
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
}
#company-name-tooltip > .tooltip-inner {
  background-color: #c7c9cc;
  color: #070707;
  font-size: 13px;
  opacity: 1;
}

.client-tooltip.tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #c7c9cc;
}

.client-tooltip.tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #c7c9cc;
}

.client-tooltip.tooltip.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #c7c9cc;
}

.client-tooltip.tooltip.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #c7c9cc;
}
.company-name-tooltip {
  z-index: 1000000;
}

// Bulk Update
.updateCheckbox.form-check {
  min-height: 0 !important;
}
.updateCheckbox .form-check-label {
  color: $sort-green !important;
}

table.table-bulk-update-account{
    thead {
      background-color: $white;
      tr {
        th {
          color: $sort-green;
        }
      }
    }
    tbody {
      tr {
        td {
          color: rgba(0, 0, 0, 0.5);
          vertical-align: middle;
          text-transform: capitalize;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    border-color: transparent;
    &.table-striped > tbody > tr:nth-of-type(odd) > * {
      background-color: #f9f9f9;
      --bs-table-accent-bg: #f9f9f9;
      color: rgba(0, 0, 0, 0.5);
    }
    &.table-striped > tbody > tr:nth-of-type(even) > * {
      background-color: $white;
      color: rgba(0, 0, 0, 0.5);
    }
}

.update-bulk-modal {
  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 550px;
    }
  }
}

.bulk-modal-body {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: #55636b;
}

.warning-message-cancel {
  padding: 20px;
  color: #9a9fa6;
  border: none;
  font-size: 16px;
  text-align: center;
}

.title-total {
  color: rgba(0, 0, 0, 0.5);
  padding-top: 8px !important;
  padding-bottom: 10px !important;
  padding-left: 10px !important;
}

.output-total {
  color: rgba(0, 0, 0, 0.5);
  padding-top: 8px !important;
  padding-bottom: 10px !important;
}

.style-total {
  font-size: 12px;
}

.cost-row-total {
  margin-left: 0;
  margin-right: 0;
  background-color: #f9f9f9;
  border-bottom: 1px solid #d1cece;
}

.custom-cost-inaction-table {
  table {
    border-color: transparent;
    &.table-striped > tbody > tr:nth-of-type(odd) > * {
      background-color: #f9f9f9;
      --bs-table-accent-bg: #f9f9f9;
      color: rgba(0, 0, 0, 0.5);
    }
    &.table-striped > tbody > tr:nth-of-type(even) > * {
      background-color: $white;
      color: rgba(0, 0, 0, 0.5);
    }
    > :not(:first-child) {
      border-top: none;
    }
  }

  thead {
    background-color: $white;
    tr {
      th {
        color: $sort-green;
      }
    }
  }
  tbody {
    tr {
      td {
        color: rgba(0, 0, 0, 0.5);
        vertical-align: middle;
        text-transform: capitalize;
      }
    }
  }
}

.custom-cost-inaction-table.cost-inaction-table {
  box-shadow: none;
  table-layout: auto;
    margin-bottom: 0 !important;
    
    th {
      font-size: 12px;
      padding-top: 15px !important;
      padding-bottom: 15px !important;
      border-bottom: 1px solid $sort-green;
        border-top-left-radius: 5px;
        padding-left: 10px;
        width: 50%;
        pointer-events: none;
    }
    tbody {
      td {
          padding-left: 10px !important;
          width: 50%;
          color: rgba(0, 0, 0, 0.5) !important;
          text-decoration: none !important;
        font-size: 12px;
        padding-top: 8px !important;
        padding-bottom: 8px !important;
        border-bottom: 1px solid #d1cece;
        pointer-events: none;
      }
    }
}

.table-with-total {
  box-shadow: 0px 16px 24px rgb(0 0 0 / 12%);
}

.report-tab-admin {
  font-size: 14px !important;
}

//gp2-dashboard styles
.dashboard {
  .h1, .h2, .h3, .h4, .h5 { 
    font-family: 'focoBold'; 
    font-weight: normal; 
    color: $sort-gray
  };
  min-height: 90vh;
  background-color: #eff6e8;
  margin-top: -20px;
  span {
    font-size: 1rem;
  }
   div {
    font-size: 1rem;
    font-weight: bold;
  }
  .left-panel {
    padding: 0;
    h1 {
      height: 55px;
      background-color: $sort-green-shade1;
      span {
        font-weight: 700;
        font-size: 1rem;
      }
    }
    h2 {
      span{
        color: $sort-green;
      } 
    }
    .min-height-100{
      min-height: 100%;
    }
  }
  #steps-menu{
    border-radius: 25px;
    padding:15px;
    background-color: $sort-green-shade2;
  }
  .expand-panel {
    border-radius: 10px !important;
    background-color: $primary-gray;
    color: $sort-gray;
    .header {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background-color: $sort-green;
      color: $primary-gray;
      padding: 10px 5px;
    }
    .tbl-wrapper {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border: 2px solid $sort-gray-shade3;
      overflow-y: auto;
    }
    .table-bordered {
      border-color: $sort-gray-shade3;
      border-style: hidden ;
      color: $sort-gray;
      font-weight: normal;
      thead {
        background-color: $sort-green-shade3;
        .caller {
          padding-left: 15px;
        }
        tr th{
          background-color: #E4F1D7;
          padding-left: 15px;
        }
      }
      td {
        padding-left: 15px;
        .table-link-color{
          color: #212529;
        }
        span.file-size-color{
          color: #a9adb0;
        }
        .company-name-link{
          text-decoration: underline;
          color: #212529 !important;
        }
      }
      th,td {
        border-width: 2px;
      }
    }
    .css-b62m3t-container{
      color: #212529;
      .css-13cymwt-control{
        background-color: #EFF6E8;
        color: #55626C;
        border-color: #77BF4B;
        font-weight: bold;
      }
      
    }
  }
  .panel-op {
    width: 50px;
    height: 50px;
  }
  .pdf-icon {
    width: 20px;
    height: 20px;
  }
  .fileSize {
    color: $sort-gray-shade1;
  }
  .expand-row{
    bottom: 0;
    position: absolute;
    right: 17px;
    &.custom-styles{
      position: relative;
    }
  }
}

.icon-wrapper {
  display: inline-block;
  transition: all 0.4s;
  border: 0.4px solid $sort-green-shade1;
  background-color: $primary-gray;
  border-radius: 15px;
  color: $sort-green-dark;
  .icon {
      font-size: 2rem;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 75px;
      height: 75px;
      padding: 8px;
  }
  &.contact-icon {
      color: #ffffff;
      background-color: $sort-green-dark;
  }
}
.icon-steps {
  font-size: 2rem;
  font-weight: bold;
  display: inline-block;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  padding: 3px;
  margin-bottom: 5px;
}

//panel
.widget-layout {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
  border-radius: 25px;
  padding:20px;
  font-size: 1rem;
  font-weight: bold;

  // span {
  //     font-size: 1rem;
  //     font-weight: bold;
  // }
  .caption {
      display: flex;
      align-items:center;
      font-size: 1rem;
      font-weight: bold;
  }
  .sub-caption{
      font-weight: bold;
      font-size: 1.1rem;
      color: $sort-green-dark;
  }
  .fullwidth-flex {
      width: 100%;
      justify-content: center;
      display: flex;
      align-items:center;
  }
  .value {
      color: $sort-green-dark;
      font-size: 4rem;
  }
  .value-desc {
      display: flex;
      align-items:center;
      color: $sort-green-dark;
  }
  .caption-wrapper {
      border-right: 1px solid $sort-green-shade1;
      padding: 50px 10px;
      text-align: center;
  }
  .caption-wrapper img {
    max-width: 150px;  
    width: 100%;       
    height: auto;      
  }
  .value-wrapper {
      padding-left: 2rem;
  }
  .padding-bottom-15 {
    padding-bottom: 15px;
  }  
  @media (min-width: 1248px) {
    .no-wrap-responsive {
      white-space: nowrap;
    }
  }
}

@media only screen and (max-width : 1024px) {
  .caption-wrapper {
      padding: auto !important;
  }
}

.modal-body{
  .main-container{
    max-width: 1000px;
    margin: 0 auto;
    background: #eee;
    .video-container {
      position: relative;
      padding-bottom: 56.25%;
      max-width: 940px;
      margin: 0 auto;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    
  }
}

.client-info-section{
  background-color: #F7FBF2;
  border: 1px solid #B9DA9E;
  color:black;
  border-radius: 25px;
  padding: 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);   
    .client-info-span{
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ol{
      li{
        span{
          font-family: "focoBold";
          font-weight: normal;
        }
      }
    }
      &.increaseHeight{
        overflow: visible;
        white-space: normal;
      }
    }
}
.gpd-btn-search{
  background-color: #EFF6E8;
  color: #55626C;
  border-color: #77BF4B;
  font-weight: bold;
  width: 100%;
  &:hover{
    background-color: #daebcc !important;
  color: #55626C !important;
  border-color: #77BF4B !important;
  }
}

.epc-section-break{
  background-color: #E9F3E1;
  padding: 10px
}

.epc-summary-page{
  .epc-section-lable{
    color: black;
    font-size: 16px;
    font-family: 'focoBold';
  }
  .epc-section-sub-label{
    font-size: 16px;
    font-family: 'focoLight';
  }
  .epc-legend-colors{
    .badge{
      padding-right: 25px;
      border-radius: 5px 13px 13px 5px;
    }
    .epc-red{
      background-color:#FE0000 !important;
      padding-right: 25px;
      border-radius: 5px 13px 13px 5px;
    }
    .epc-orange{
      background-color:#FF7500 !important;
    }
    .epc-chineeseYellow{
      background-color:#FFB200 !important;
    }
    .epc-yellow{
      background-color:#FFFF01 !important;
      color: #9c9696;
    }
    .epc-lightGreen{
      background-color:#89DF44 !important;
    }
    .epc-green{
      background-color:#00BF2E !important;
    }
    .epc-darkGreen{
      background-color:#007500 !important;
    }
    .epc-green-outliner{
      background-color:#fff !important;
      border: 1px solid #007500;
      color: #007500 !important
    }

  }
}
.preview-table-score-table{
  table{
    background: linear-gradient(0deg,#f9f9f9,#f9f9f9),#3e66fb;
    box-shadow: 0 16px 24px rgba(0,0,0,.12);
    border-radius: 8px;
    border-top: 3px solid #77bf4b;
    th{
      color: #727070 !important;
    padding: 10px;
    background-color: #daebcc;
    .col-left-padding{
          padding-left: 20px;
        }
        
    &:first-child{
      width:10% !important;
      border-right: 1px solid #e4dede;
    }
    &:last-child{
      width:90% !important;
    }
    }
    td{
      padding: 15px 10px;
    color: #727070;
    font-size: 15px;
    font-weight: 600;
    pointer-events: none;
    &:first-child{
      border-right: 1px solid #e4dede;
    }
    }
  }
}

.custom-tooltip {
  .tooltip-inner {
    background-color: #7e807d !important;
    color: rgb(255, 255, 255) !important;
    font-family: "focoLight";
    font-weight: bold;
  }
}