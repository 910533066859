body {
  background-color: $body-bg;
  font-size: $font-size-base;
  font-family: "focoLight";
  display: block;
  overflow: auto;
}
@media only screen and (max-width : 1024px) {
  body {
  font-size: 0.6875rem;

}
}

* {
  box-sizing: border-box;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: $h1-font-size;
}

h2,
.h2 {
  font-size: $h2-font-size;
}

h3,
.h3 {
  font-size: $h3-font-size;
}

h4,
.h4 {
  font-size: $h4-font-size;
}

h5,
.h5 {
  font-size: $h5-font-size;
}

h6,
.h6 {
  font-size: $h6-font-size;
}
p {
  font-size: $font-size-base;
  margin-top: 0;
  margin-bottom: 1rem;
}
// form elements
.sort-form-input {
  border-radius: $form-text-border-radius;
  color: $form-text-color;
  font-size: $h5-font-size;
  padding: 15px 15px;
  &:focus {
    background-color: #f9f9f9;
    border-color: $sort-green;
    outline: 0;
    box-shadow: none !important;
  }
  &.errorField {
    border: 1px solid $error;
    color: $error;
  }
}
.sort-form-input[readonly] {
  border: 1px solid #c7c9cc;
  &:focus {
    border: 1px solid #c7c9cc;
    box-shadow: none !important;
    background-color: $primary-gray;
    color: #c7c9cc;
  }
  &.errorField {
    border: 1px solid $error;
  }
}
.sort-form-select {
  border-radius: $form-text-border-radius;
  color: $form-text-color;
  font-size: $h5-font-size;
  padding: 10px 15px;
  &:focus {
    background-color: #fff;
    border-color: $sort-green;
    outline: 0;
    box-shadow: none !important;
  }
}
.form-header-h1 {
  // font-family: 'focoBold';
}
.form-breadcrum-title {
  // font-family: 'focoBold';
  color: $sort-green;
  font-weight: 600;
}

.client-dropdown{
  padding: inherit !important;
  border-radius: 4px !important;
  background: #77BF4B !important;
  font-family: "focoLight" !important;
  font-size: 1.1rem !important;
  color: white !important;
  font-weight: bolder !important;
}

.sort-form-lable {
  font-family: "focoLight";
  font-size: $h6-font-size;
  color: $sort-gray;
  &.green-lable {
    color: $sort-green;
    &.font-bold{
      font-weight: 600;
    }
  }
  &.error-lable {
    color: $error;
  }
  &.disabled {
    color: #c7c9cc;
  }
}
.sort-form-section-lable {
  font-family: "focoLight";
  font-size: $h5-font-size;
  color: $sort-gray;
  font-weight: 700;
}

//colors
.sort-primary-btn {
  background-color: $sort-green;
  color: $white;
  border: 1px solid $sort-green;
  //width:120px;
  &:active {
    background-color: $sort-green;
    border: 1px solid $sort-green;
  }
  &:focus {
    background-color: $sort-green;
    border: 1px solid $sort-green;
    box-shadow: none !important;
  }
  &:hover {
    background-color: #63a837;
    border: 1px solid $sort-green !important;
    box-shadow: none !important;
  }
  &.btn-size {
    width: 120px;
  }
  &.btn-resize {
    width: 100%;
  }
  &.disable {
    background-color: $sort-gray;
    color: rgb(211, 211, 211);
    border: 1px solid $sort-gray;
  }
}
@media only screen and (max-width : 1024px) {
  .sort-primary-btn {
    font-size: 0.6875rem;
  }

}
.sort-primary-outline-btn {
  background-color: #f9f9f9;
  color: $sort-green;
  border: 1px solid $sort-green;
  //width:120px;
  &:active {
    background-color: #f9f9f9;
    color: $sort-green;
    border: 1px solid $sort-green;
  }
  &:focus {
    background-color: #f9f9f9;
    color: $sort-green;
    box-shadow: none !important;
    border: 1px solid $sort-green;
  }
  &:hover {
    background-color: $sort-green;
    color: $white;
    border: 1px solid $sort-green;
    box-shadow: none !important;
  }
  &.btn-size {
   // width: 120px;
  }
}
.sort-gray-outline-btn {
  background-color: #f9f9f9;
  color: $sort-green;
  border: 1px solid #cccaca;
  &:active {
    background-color: $white;
    color: $sort-green;
    border: 1px solid $sort-green;
  }
  &:focus {
    background-color: $white;
    color: $sort-green;
    box-shadow: none !important;
    border: 1px solid $sort-green;
  }
  &:hover {
    background-color: $sort-green;
    color: $white;
    border: 1px solid $sort-green;
    box-shadow: none !important;
  }
  &.disable {
    background-color: #f9f9f9;
    color: rgb(211, 211, 211);
    border: 1px solid #cccaca;
    pointer-events: none;
  }
}
.sort-btn-link {
  background-color: #f9f9f9;
  color: #1786be;
  border: 1px solid #f9f9f9;
  font-size: 13px;
  font-weight: 500;
  //width:120px;
  &:active {
    background-color: #f9f9f9;
    color: #1786be;
    border: 1px solid #f9f9f9;
    text-decoration: underline;
  }
  &:active:focus {
    box-shadow: none;
  }
  &:focus {
    background-color: #f9f9f9;
    color: blue;
    border: 1px solid #f9f9f9;
    text-decoration: underline;
    box-shadow: none;
  }
  &:hover {
    background-color: #f9f9f9;
    color: blue;
    border: 1px solid #f9f9f9;
    text-decoration: underline;
  }
  text-decoration: underline;
}
.btn-min-width {
  min-width: 120px;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-left: 0 !important;
}

.me-1 {
  margin-left: 0.25rem !important;
}

.me-2 {
  margin-left: 0.5rem !important;
}

.me-3 {
  margin-left: 1rem !important;
}

.me-4 {
  margin-left: 1.5rem !important;
}

.me-5 {
  margin-left: 3rem !important;
}

.me-auto {
  margin-left: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-right: 0 !important;
}

.ms-1 {
  margin-right: 0.25rem !important;
}

.ms-2 {
  margin-right: 0.5rem !important;
}

.ms-3 {
  margin-right: 1rem !important;
}

.ms-4 {
  margin-right: 1.5rem !important;
}

.ms-5 {
  margin-right: 3rem !important;
}

.ms-auto {
  margin-right: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mx-n1 {
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important;
}

.mx-n2 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}

.mx-n3 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.mx-n4 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.mx-n5 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.me-n1 {
  margin-left: -0.25rem !important;
}

.me-n2 {
  margin-left: -0.5rem !important;
}

.me-n3 {
  margin-left: -1rem !important;
}

.me-n4 {
  margin-left: -1.5rem !important;
}

.me-n5 {
  margin-left: -3rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.ms-n1 {
  margin-right: -0.25rem !important;
}

.ms-n2 {
  margin-right: -0.5rem !important;
}

.ms-n3 {
  margin-right: -1rem !important;
}

.ms-n4 {
  margin-right: -1.5rem !important;
}

.ms-n5 {
  margin-right: -3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-left: 0 !important;
}

.pe-1 {
  padding-left: 0.25rem !important;
}

.pe-2 {
  padding-left: 0.5rem !important;
}

.pe-3 {
  padding-left: 1rem !important;
}

.pe-4 {
  padding-left: 1.5rem !important;
}

.pe-5 {
  padding-left: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-right: 0 !important;
}

.ps-1 {
  padding-right: 0.25rem !important;
}

.ps-2 {
  padding-right: 0.5rem !important;
}

.ps-3 {
  padding-right: 1rem !important;
}

.ps-4 {
  padding-right: 1.5rem !important;
}

.ps-5 {
  padding-right: 3rem !important;
}
.p-r-0 {
  padding-right: 0 !important;
}
.p-l-0 {
  padding-left: 0 !important;
}

// Audit Update

.sort-primary-outline-audit-btn {
  background-color: #f9f9f9;
  color: #55636b;
  border: none;
  //border: 1px solid $sort-green;
  //width:120px;
  &:active {
    background-color: $sort-green;
    color: $white;
    // border: 1px solid $sort-green;
    box-shadow: none !important;
    border: none;
    // border: 1px solid $sort-green;
  }
  &:focus {
    background-color: $sort-green;
    color: $white;
    // border: 1px solid $sort-green;
    box-shadow: none !important;
    border: none;
    // border: 1px solid $sort-green;
  }
  &:hover {
    background-color: $sort-green;
    color: $white;
    // border: 1px solid $sort-green;
    box-shadow: none !important;
    border: none;
  }
  &.btn-size {
    width: 120px;
  }
}

.sort-primary-outline-audit-active-btn {
  background-color: $sort-green;
  color: $white;
  // border: 1px solid $sort-green;
  box-shadow: none !important;
  border: none;
  &:active {
    background-color: $sort-green;
    color: $white;
    // border: 1px solid $sort-green;
    box-shadow: none !important;
    border: none;
    // border: 1px solid $sort-green;
  }
  &:focus {
    background-color: $sort-green;
    color: $white;
    // border: 1px solid $sort-green;
    box-shadow: none !important;
    border: none;
    // border: 1px solid $sort-green;
  }
  &:hover {
    background-color: $sort-green;
    color: $white;
    // border: 1px solid $sort-green;
    box-shadow: none !important;
    border: none;
  }
  &.btn-size {
    width: 120px;
  }
}

.hr-audit-update {
  color: $sort-green !important;
  height: 5px !important;
  opacity: 1;
}

.sort-primary-outline-report-btn {
  background-color: #eff6e8;
  color: $sort-green;
  border: 1px solid $sort-green;
  //width:120px;
  &:active {
    background-color: #eff6e8;
    color: $sort-green;
    border: 1px solid $sort-green;
  }
  &:focus {
    background-color: #eff6e8;
    color: $sort-green;
    box-shadow: none !important;
    border: 1px solid $sort-green;
  }
  &:hover {
    background-color: $sort-green;
    color: $white;
    border: 1px solid $sort-green;
    box-shadow: none !important;
  }
  &.btn-size {
    width: 120px;
  }
}

.sort-primary-add-report-btn {
  background-color: #c7c9cc;
  color: $white;
  border-radius: 5px;
  border: none;
  //width:120px;
  &:active {
    background-color: #c7c9cc;
  }
  &:focus {
    background-color: #c7c9cc;
    box-shadow: none !important;
  }
  &:hover {
    background-color: #c7c9cc;
    box-shadow: none !important;
  }
  &.btn-size {
    width: 120px;
  }
  &.btn-resize {
    width: 100%;
  }
  &.disable {
    background-color: #c7c9cc;
    color: rgb(211, 211, 211);
  }
}
// Audit Section

.sort-primary-outline-audit-section-btn {
  background-color: #f9f9f9;
  color: #55636b;
  border: none;
  border-radius: none !important;
  padding-left: 70px !important;
  padding-right: 70px !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 15px !important;

  &:active {
    background-color: #f9f9f9;
    color: #77bf4b;
    box-shadow: none !important;
    padding-left: 70px !important;
    padding-right: 70px !important;
    border-bottom: 9px solid $sort-green !important;
  }
  &:focus {
    background-color: #f9f9f9;
    color: #77bf4b;
    box-shadow: none !important;
    border-bottom: 9px solid $sort-green !important;
  }
  &:hover {
    background-color: #f9f9f9;
    color: #77bf4b;
    box-shadow: none !important;
    border: none;
  }
  &.btn-size {
    width: 120px;
  }
}

.sort-primary-outline-audit-section-btn.active {
  background-color: #f9f9f9;
  color: #77bf4b;
  box-shadow: none !important;
  padding-left: 70px !important;
  padding-right: 70px !important;
  border-bottom: 9px solid $sort-green !important;
  padding-left: 70px !important;
  padding-right: 70px !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 15px !important;
}

.sort-primary-add-video-btn {
  background: linear-gradient(0deg, #4088c5, #4088c5), #3e66fb;
  color: $white;
  border-radius: 5px;
  border: none;
  //width:120px;
  &:active {
    background: linear-gradient(0deg, #4088c5, #4088c5), #3e66fb;
  }
  &:focus {
    background: linear-gradient(0deg, #4088c5, #4088c5), #3e66fb;
    box-shadow: none !important;
  }
  &:hover {
    background: linear-gradient(0deg, #4088c5, #4088c5), #3e66fb;
    box-shadow: none !important;
  }
  &.btn-size {
    width: 120px;
  }
  &.btn-resize {
    width: 100%;
  }
  &.disable {
    background-color: #c7c9cc;
    color: rgb(211, 211, 211);
  }
}
.posi-relative {
  position: relative;
}
.posi-absolue {
  position: absolute;
}
.posi-absolue-default {
  position: absolute;
  top: 0;
  right: 0;
}

.sort-primary-add-video-outline-btn {
  background-color: #f9f9f9;
  color: #4088c5;
  border-radius: 5px;
  border: 1px solid #4088c5;
  //width:120px;
  &:active {
    background-color: #f9f9f9;
    color: #4088c5;
    border-radius: 5px;
    border: 1px solid #4088c5;
  }
  &:focus {
    background-color: #f9f9f9;
    color: #4088c5;
    border-radius: 5px;
    border: 1px solid #4088c5;
    box-shadow: none !important;
  }
  &:hover {
    background-color: #f9f9f9;
    color: #4088c5;
    border-radius: 5px;
    border: 1px solid #4088c5;
    box-shadow: none !important;
  }
  &.btn-size {
    width: 120px;
  }
  &.btn-resize {
    width: 100%;
  }
  &.disable {
    background-color: #c7c9cc;
    color: rgb(211, 211, 211);
  }
}

.file-progress-bar .progress-bar{
  background-color: #4088C5;
}

.form-check-label{
  color: #55636B;
}

// MyDashboard

.audit-sort-btn-link {
  background-color: #77BF4B;
  color: #FFFFFF;;
  border: 1px solid #77BF4B;
  font-size: 13px;
  font-weight: 500;
  //width:120px;
  &:active {
    background-color: #77BF4B;
    color: #FFFFFF;;
    border: 1px solid #77BF4B;
    text-decoration: underline;
  }
  &:active:focus {
    box-shadow: none;
  }
  &:focus {
    background-color: #77BF4B;
    color: #FFFFFF;;
    border: 1px solid #77BF4B;
    text-decoration: underline;
    box-shadow: none;
  }
  &:hover {
    background-color: #77BF4B;
    color: #FFFFFF;;
    border: 1px solid #77BF4B;
    text-decoration: underline;
  }
  text-decoration: underline;
}
.green-header{
  font-size: 18px;
  font-weight: 600;
  color: #77bf4b;
  margin: 0 5px;
}
.iconography-table-wrapper{
.table-iconography{
  background-color: $white;
  th{
    text-align: center;
    color: #55636B;
    &:first-child{
      width:15%
    }
    &:nth-child(2){
      width:85%
    }
  }
  tbody{
    border-top: none;
    td:first-child{
      text-align: center;
      padding: 10px;
      vertical-align: middle;
      cursor: default;
    }
    td{
      color:#9A9FA6;
      font-size:13px;
      cursor: default;
    }
  }
}
}
.text-right{
  text-align: right;
}
.badge-green{
  background-color: $sort-green !important;
  padding: 5px 12px;
    font-size: 15px;
    border-radius: 6px;
    font-weight: 500
}
.badge-amber{
  background-color: #F3BB1C !important;
  padding: 5px 12px;
    font-size: 15px;
    border-radius: 6px;
    font-weight: 500
}
.badge-red{
  background-color: #F03738 !important;
  padding: 5px 12px;
    font-size: 15px;
    border-radius: 6px;
    font-weight: 500
}
.react-bootstrap-table{
  tbody{
    td{
      cursor: default;
    }
  }
}