.page-topbar{
  background-color: $primary-gray;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid $primary-gray;
  box-shadow: 5px 1px 5px $sort-gray-shade2;
  color:$sort-gray;
  position: fixed;
    top: 0;
    right: 0;
    left: 250px;
    z-index: 1002;
    .header-title{
      font-size: 18px;
      color: $sort-green;
      font-weight: 600;
    }
    .header-logout{
      border: none;
      padding: 0;
      background-color: $primary-gray !important;
      &:focus{
        border:none;
        box-shadow: none !important;
      }
    }
}
/* Medium devices (landscape tablets, 768px and down) */
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape){
  .page-topbar {
    left:200px !important;
    .header-title{
      font-size: 15px;
    }
    .dropdown{
      button{
        span{
          font-size: 15px !important;
        }
      }
    }
    &.collapsed-page-topbar{
      left:60px !important;
      z-index: 0;
    }
  }
}
/* Medium devices (landscape tablets, 768px and down) */
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait){
  .page-topbar {
    left:0 !important;
    .header-title{
      font-size: 15px;
    }
    .dropdown{
      button{
        span{
          font-size: 15px !important;
        }
      }
    }
    &.collapsed-page-topbar{
      left:60px !important;
      z-index: 0;
    }
  }
}
@media only screen
and (min-width: 360px)
and (max-width: 385px){
  .page-topbar {
    left:0 !important;
}
}
//iphone 12 pro
@media only screen 
    and (device-width: 390px) 
    and (device-height: 844px) 
    and (-webkit-device-pixel-ratio: 3) {
      .page-topbar {
    left:0 !important;
}
    }