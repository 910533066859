table.dataTable thead > tr > th.sorting_asc,
table.dataTable thead > tr > th.sorting_desc,
table.dataTable thead > tr > th.sorting,
table.dataTable thead > tr > td.sorting_asc,
table.dataTable thead > tr > td.sorting_desc,
table.dataTable thead > tr > td.sorting {
  padding-right: 30px;
}
table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none;
}
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  bottom: 0.9em;
  display: block;
  opacity: 0.3;
}
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  right: 1em;
  content: "\2191";
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  right: 0.5em;
  content: "\2193";
}
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:after {
  opacity: 1;
}
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  opacity: 0;
}
div.dataTables_scrollHead table.dataTable {
  margin-bottom: 0 !important;
}
div.dataTables_scrollBody table {
  border-top: none;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
div.dataTables_scrollBody table thead .sorting:before,
div.dataTables_scrollBody table thead .sorting_asc:before,
div.dataTables_scrollBody table thead .sorting_desc:before,
div.dataTables_scrollBody table thead .sorting:after,
div.dataTables_scrollBody table thead .sorting_asc:after,
div.dataTables_scrollBody table thead .sorting_desc:after {
  display: none;
}
div.dataTables_scrollBody table tbody tr:first-child th,
div.dataTables_scrollBody table tbody tr:first-child td {
  border-top: none;
}
div.dataTables_scrollFoot > .dataTables_scrollFootInner {
  box-sizing: content-box;
}
div.dataTables_scrollFoot > .dataTables_scrollFootInner > table {
  margin-top: 0 !important;
  border-top: none;
}
@media screen and (max-width: 767px) {
  div.dataTables_wrapper div.dataTables_length,
  div.dataTables_wrapper div.dataTables_filter,
  div.dataTables_wrapper div.dataTables_info,
  div.dataTables_wrapper div.dataTables_paginate {
    text-align: center;
  }
}
table.dataTable.table-sm > thead > tr > th {
  padding-right: 20px;
}
table.dataTable.table-sm .sorting:before,
table.dataTable.table-sm .sorting_asc:before,
table.dataTable.table-sm .sorting_desc:before {
  top: 5px;
  right: 0.85em;
}
table.dataTable.table-sm .sorting:after,
table.dataTable.table-sm .sorting_asc:after,
table.dataTable.table-sm .sorting_desc:after {
  top: 5px;
}
table.table-bordered.dataTable th,
table.table-bordered.dataTable td {
  border-left-width: 0;
}
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable th:last-child,
table.table-bordered.dataTable td:last-child,
table.table-bordered.dataTable td:last-child {
  border-right-width: 0;
}
table.table-bordered.dataTable tbody th,
table.table-bordered.dataTable tbody td {
  border-bottom-width: 0;
}
div.dataTables_scrollHead table.table-bordered {
  border-bottom-width: 0;
}
div.table-responsive > div.dataTables_wrapper > div.row {
  margin: 0;
}
div.table-responsive
  > div.dataTables_wrapper
  > div.row
  > div[class^="col-"]:first-child {
  padding-left: 0;
}
div.table-responsive
  > div.dataTables_wrapper
  > div.row
  > div[class^="col-"]:last-child {
  padding-right: 0;
}
tbody tr {
  cursor: pointer;
}
.dropup > .caret {
  margin: 10px 0;
  color: #cccccc;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
}
.react-bootstrap-table th > .react-bootstrap-table-sort-order > .caret {
  margin: 10px 6.5px;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.custom-data-table {
  overflow-x: auto;
  table {
    border-color: transparent;
    &.table-striped > tbody > tr:nth-of-type(odd) > * {
      background-color: #f9f9f9;
      --bs-table-accent-bg: #f9f9f9;
      color: rgba(0, 0, 0, 0.5);
    }
    &.table-striped > tbody > tr:nth-of-type(even) > * {
      background-color: $white;
      color: rgba(0, 0, 0, 0.5);
    }
    > :not(:first-child) {
      border-top: none;
    }
  }

  thead {
    background-color: $white;
    tr {
      th {
        color: $sort-green;
        cursor: pointer;
        .order-4:before {
          margin-left: 3.5px;
          content: "\2191";
          opacity: 1;
          color: rgba(0, 0, 0, 0.5);
        }
        .order-4:after {
          content: "\2193";
          opacity: 1;
          color: rgba(0, 0, 0, 0.5);
        }
        &:first-child {
          width: 250px;
        }
        &:nth-child(2),
        &:nth-child(3) {
          width: 150px;
        }
        &:nth-child(4) {
          width: 300px;
        }
        &:nth-child(5) {
          width: 90px;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        color: rgba(0, 0, 0, 0.5);
        padding: 15px 15px;
        vertical-align: middle;
        // text-transform: capitalize;
        &:first-child {
          color: $sort-green;
          text-decoration: underline;
        }
      }
    }
  }
}
.react-bootstrap-table table {
  table-layout: fixed;
}

.react-bootstrap-table th.sortable {
  cursor: pointer;
}

.react-bootstrap-table th .order > .dropdown > .caret {
  margin: 10px 0 10px 5px;
  color: #cccccc;
}

.react-bootstrap-table th .order > .dropup > .caret {
  margin: 10px 0;
  color: #cccccc;
}

.react-bootstrap-table th > .react-bootstrap-table-sort-order > .caret {
  margin: 10px 6.5px;
}

.react-bootstrap-table th .order-4:before {
  margin-left: 3.5px;
  content: "\2191";
  opacity: 0.4;
}

.react-bootstrap-table th .order-4:after {
  content: "\2193";
  opacity: 0.4;
}

.react-bootstrap-table th .caret-4-asc:before {
  margin-left: 3.5px;
  content: "\2191";
}

.react-bootstrap-table th .caret-4-asc:after {
  content: "\2193";
  opacity: 0.4;
}

.react-bootstrap-table th .caret-4-desc:before {
  margin-left: 3.5px;
  content: "\2191";
  opacity: 0.4;
}

.react-bootstrap-table th .caret-4-desc:after {
  content: "\2193";
}

.react-bootstrap-table th[data-row-selection] {
  width: 30px;
}

.react-bootstrap-table th > .selection-input-4,
.react-bootstrap-table td > .selection-input-4 {
  margin: -4px;
}

.react-bootstrap-table td.react-bs-table-no-data {
  text-align: center;
}

.react-bootstrap-table td.react-bootstrap-table-editing-cell .animated {
  animation-fill-mode: both;
}

.react-bootstrap-table td.react-bootstrap-table-editing-cell .animated.bounceIn,
.react-bootstrap-table
  td.react-bootstrap-table-editing-cell
  .animated.bounceOut {
  animation-duration: 0.75s;
}

.react-bootstrap-table td.react-bootstrap-table-editing-cell .animated.shake {
  animation-duration: 0.3s;
}

@keyframes shake {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }
  10%,
  50%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }
  30%,
  70% {
    transform: translate3d(10px, 0, 0);
  }
}

.react-bootstrap-table td.react-bootstrap-table-editing-cell .shake {
  animation-name: shake;
}

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.react-bootstrap-table td.react-bootstrap-table-editing-cell .bounceIn {
  animation-name: bounceIn;
}

@keyframes bounceOut {
  20% {
    transform: scale3d(0.9, 0.9, 0.9);
  }
  50%,
  55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }
  to {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

.react-bootstrap-table td.react-bootstrap-table-editing-cell .bounceOut {
  animation-name: bounceOut;
}

.react-bootstrap-table .reset-expansion-style {
  padding: 0;
}

.react-bootstrap-table .row-expansion-style {
  padding: 8px;
}

.react-bootstrap-table .row-expand-slide-appear {
  max-height: 0;
  overflow: hidden;
}

.react-bootstrap-table .row-expand-slide-appear-active {
  max-height: 1000px;
  transition: max-height 3s linear;
}

.react-bootstrap-table .row-expand-slide-exit {
  max-height: 1000px;
}

.react-bootstrap-table .row-expand-slide-exit-active {
  max-height: 0;
  overflow: hidden;
  transition: max-height 400ms cubic-bezier(0, 0.95, 0, 0.95);
}

.audit-table {
    border-color: transparent;
    &.table-striped > tbody > tr:nth-of-type(odd) > * {
      background-color: #f9f9f9;
      --bs-table-accent-bg: #f9f9f9;
      color: rgba(0, 0, 0, 0.5);
    }
    &.table-striped > tbody > tr:nth-of-type(even) > * {
      background-color: $white;
      color: rgba(0, 0, 0, 0.5);
    }
    > :not(:first-child) {
      border-top: none;
    }

  thead {
    background-color: $white;
    tr {
      th {
        color: $sort-green;
        padding: 15px 15px;
        .order-4:before {
          margin-left: 3.5px;
          content: "\2191";
          opacity: 1;
          color: rgba(0, 0, 0, 0.5);
        }
        .order-4:after {
          content: "\2193";
          opacity: 1;
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
  tbody {
    tr {
      td {
        color: rgba(0, 0, 0, 0.5);
        padding: 15px 15px;
        vertical-align: middle;
        text-transform: capitalize;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:nth-child(2) {
          color: $sort-green !important;
          text-decoration: underline;
          cursor: pointer !important;
        }
        &:nth-child(9) {
          cursor: pointer !important;
        }
        &:nth-child(10) {
          cursor: pointer !important;
        }
      }
    }
  }
}

.dashboard-project-table {

  thead {
    background-color: $white;
    tr {
      th {
        color: $sort-green;
        padding: 15px 15px;
        width: auto !important;
        .order-4:before {
          margin-left: 3.5px;
          content: "\2191";
          opacity: 1;
          color: rgba(0, 0, 0, 0.5);
        }
        .order-4:after {
          content: "\2193";
          opacity: 1;
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
  tbody {
    tr {
      td {
        color: rgba(0, 0, 0, 0.5);
        padding: 15px 15px;
        vertical-align: middle;
        text-transform: capitalize;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:nth-child(1) {
          color: $sort-green !important;
          text-decoration: underline;
          cursor: pointer !important;
        }
        &:nth-child(6) {
          cursor: pointer !important;
        }
      }
    }
  }
}

// View Client Projects

.client-project-table {
  table {
    border-color: transparent;

    &.table-striped > tbody > tr:nth-of-type(4n + 1) > * {
      background-color: $white;
      --bs-table-accent-bg: $white;
      color: rgba(0, 0, 0, 0.5);
    }
    &.table-striped > tbody > tr:nth-of-type(4n + 2) > * {
      .client-project-report-row {
        .card {
          background-color: $white;
          --bs-table-accent-bg: $white;
          color: rgba(0, 0, 0, 0.5);
        }
        .card-header {
          background-color: $white;
        }
      }
    }
    &.table-striped > tbody > tr:nth-of-type(4n + 3) > * {
      background-color: #f9f9f9;
      --bs-table-accent-bg: #f9f9f9;
      color: rgba(0, 0, 0, 0.5);
      
    }
    &.table-striped > tbody > tr:nth-of-type(4n + 4) > * {
      .client-project-report-row {
        .card {
          background-color: #f9f9f9;
          color: rgba(0, 0, 0, 0.5);
        }
        .card-header {
          background-color: #f9f9f9;
          border-left: 0.5px solid #e3e4e8;
          border-right: 0.5px solid #e3e4e8;
        }
      }
    }
    > :not(:first-child) {
      border-top: none;
    }
  }

  thead {
    background-color: $white;
    tr {
      th {
        color: $sort-green;
        padding: 15px 15px;
        .order-4:before {
          margin-left: 3.5px;
          content: "\2191";
          opacity: 1;
          color: rgba(0, 0, 0, 0.5);
        }
        .order-4:after {
          content: "\2193";
          opacity: 1;
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
  tbody {
    tr {
      td {
        color: rgba(0, 0, 0, 0.5);
        padding: 15px 15px;
        vertical-align: middle;
        text-transform: capitalize;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:nth-child(1) {
          color: $sort-green !important;
        }
      }
    }
  }
}

.staff-user-table{
  tbody {
    tr {
      td {
        &:nth-child(1) {
          color: $sort-green !important;
          text-decoration: underline;
          cursor: pointer !important;
        }
        &:nth-child(5) {
          cursor: pointer !important;
        }
      }
    }
  }
}
.opp-sumarries-table{
  tbody{
    tr{
      td:first-child{
        cursor: pointer !important;
      }
    }
  }
}
.opp-table,.audit-data-set-table, .exec-summary-table{
  tbody{
    tr{
      td:first-child{
        cursor: pointer !important;
      }
      td:last-child{
        cursor: pointer !important;
      }
    }
  }
}
.audit-section-table, .exec-summary-table{
  thead tr th{
    color: #77BF4B;
    padding: 15px 30px;
    &:first-child {
      width: 80% !important;
  }
  }
  tbody{
    tr{
      td{
        cursor: pointer !important;
        color: #77BF4B !important;
        padding: 15px 30px !important;
        width:10% !important;
        &:has(> div.Nolink) {
          text-decoration: none !important;
          pointer-events: none !important}
      }
      &:has(> td div.Nolink) {
        pointer-events: none !important
      }
    }
  }
}

.audit-data-set-table{
  thead tr th{
    color: #77BF4B;
    padding: 15px 30px;
    &:first-child, &:nth-child(2) {
      width: 40% !important;
  }
  }
  tbody{
    tr{
      td{
        cursor: pointer !important;
        color: #77BF4B !important;
        padding: 15px 30px !important;
        width:10% !important
      }
    }
  }
}