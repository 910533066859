.footer {
  bottom: 0;
  padding: 5px 20px;
  position: absolute;
  right: 0;
  color: $white;
  left: 250px;
  height: auto;
  background-color: $sort-gray;
  &.login-footer {
    left: 0 !important;
    position: fixed;
  }
  .footer-left-col {
  }
  .footer-right-col {
    p {
      float: right;
      a {
        color: #fff;
      }
    }
  }
}
/* Medium devices (landscape tablets, 768px and down) */
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .footer {
    left: 200px !important;
    // height: 40px;
    padding: 15px;
    font-size: 11px !important;
    &.login-footer {
      position: fixed;
    }
    &.collapsed-footer {
      left: 60px !important;
    }
    .footer-left-col {
    }
    .footer-right-col {
      p {
        float: right;
        a {
          color: #fff;
        }
      }
    }
  }
}
/* Medium devices (landscape tablets, 768px and down) */
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .footer {
    left: 0 !important;
    // height: 40px;
    padding: 15px;
    font-size: 11px !important;
    &.login-footer {
      position: fixed;
    }
    &.collapsed-footer {
      left: 60px !important;
    }
    .footer-left-col {
    }
    .footer-right-col {
      p {
        float: right;
        a {
          color: #fff;
        }
      }
    }
  }
}
/* mobile device Samsung S20*/

@media only screen and (min-width: 360px) and (max-width: 385px) {
  .footer {
    left: 0 !important;
    height: 60px;
    padding: 15px;
    font-size: 11px !important;
    overflow-y: hidden;
    &.login-footer {
      position: fixed;
    }
    &.collapsed-footer {
      left: 60px !important;
    }
    .footer-left-col {
      text-align: center;
    }
    .footer-right-col {
      text-align: center;
      p {
        float: none !important;
        a {
          color: #fff;
        }
      }
    }
  }
}

//iphone 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .footer {
    left: 0 !important;
    height: 60px;
    padding: 15px;
    font-size: 11px !important;
    overflow-y: hidden;
    &.login-footer {
      position: fixed;
    }
    &.collapsed-footer {
      left: 60px !important;
    }
    .footer-left-col {
      text-align: center;
    }
    .footer-right-col {
      text-align: center;
      p {
        float: none !important;
        a {
          color: #fff;
        }
      }
    }
  }
}
