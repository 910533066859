//styles from index.css
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
// ---------------------

//styles from App.css

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
// ---------------------
.main-content {
  margin-left: 250px;
  overflow: hidden;
  .page-content {
    padding: calc(70px + 24px) calc(24px / 2) 90px calc(24px / 2);
    &.client-view {
      padding: 63px 12px 60px 12px;
    }
    &.create-staff-content {
      padding: calc(70px + 24px) calc(24px / 2) 100px calc(24px / 2);
    }
    &.view-staff-content {
      padding: calc(70px + 24px) calc(24px / 2) 100px calc(24px / 2);
      min-height: 100vh;
      overflow-y: auto;
    }
  }
  background: linear-gradient(0deg, #f9f9f9, #f9f9f9), #3e66fb;
}

/* Medium devices (landscape tablets, 768px and down) */
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .main-content {
    margin-left: 200px !important;
    .page-content {
      padding: 70px calc(24px / 2) 60px calc(24px / 2) !important;
    }
    &.collapsed-main-content {
      margin-left: 60px !important;
    }
  }
  .page-content {
    &.login-page-content {
      touch-action: none;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .main-content {
    margin-left: 200px !important;
    .page-content {
      padding: 70px calc(24px / 2) 60px calc(24px / 2) !important;
    }
    &.collapsed-main-content {
      margin-left: 60px !important;
    }
  }
  .page-content {
    &.login-page-content {
      touch-action: none;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .main-content {
    margin-left: 0 !important;
    .page-content {
      padding: 70px 10px 60px 10px !important;
      &.client-view {
        padding: 70px 10px 60px 10px !important;
        .tab-content {
          padding: 0 10px !important;
        }
      }
    }
  }
}
// samsun galaxy S20
@media only screen and (min-width: 360px) and (max-width: 767px) {
  .main-content {
    margin-left: 0 !important;
    font-size: 12px;
    .page-content {
      padding: 70px 10px 60px !important;
      .container-fluid {
        padding: 0;
      }
      &.client-view {
        padding: 70px 10px 60px !important;
        .tab-content {
          padding: 0 5px !important;
        }
      }
    }
  }
  .page-content {
    &.login-page-content {
      touch-action: none;
    }
  }
}
//iphone 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .main-content {
    margin-left: 0 !important;
    .page-content {
      padding: 70px 10px 60px 10px !important;
      &.client-view {
        padding: 70px 10px 60px 10px !important;
        .tab-content {
          padding: 0 10px !important;
        }
      }
    }
  }
  .page-content {
    &.login-page-content {
      touch-action: none;
    }
  }
}

//override bootstrap styles

.form-check-input:checked {
  background-color: $sort-green;
  border-color: $sort-green;
}

.accordion {
  //box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
  border-radius: 8px;
  .accordion-item {
    .accordion-header {
      input {
        font-size: $h5-font-size;
        margin: 0;
        margin-right: 10px;
      }
      p {
        margin: 0;
        font-size: $h5-font-size;
        font-weight: 600;
        color: $sort-gray;
      }
      .accordion-button:not(.collapsed) {
        color: $black;
        background-color: $white;
        box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
      }
      .accordion-button:not(.collapsed)::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
        transform: rotate(-180deg);
      }
      .accordion-button:focus {
        z-index: 3;
        border: 1px solid rgba(0, 0, 0, 0.125);
        outline: 0;
        box-shadow: none;
      }
    }
    .accordion-body {
      ul {
        li {
          list-style: none;
          padding: 5px 0;
          input {
            font-size: $h5-font-size;
            margin-right: 10px;
          }
          span {
            margin: 0;
            font-size: $h5-font-size;
          }
        }
      }
    }
  }
}

.table.view-roles > :not(:first-child) {
  border-top: none;
}
.custom-data-table {
  .tab-header {
    font-weight: 700;
    line-height: 28px;
    font-size: 16px;
    font-style: normal;
    color: #55636b;
  }
  table {
    thead {
      tr {
        th {
          color: $sort-green;
          padding: 15px 30px;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 15px 30px;
          vertical-align: middle;
          // text-transform: lowercase;

          &.clickable-cell a {
            color: $sort-green;
            text-decoration: underline;
            cursor: pointer;
          }
          &.clickable-icon-cell {
            text-align: end;
            .edit-img {
              cursor: pointer;
            }
            .preview-img {
              cursor: pointer;
            }
          }
        }
      }
    }
    &.view-client-accounts {
      thead {
        th {
          &:first-child {
            width: 25%;
          }
          &:nth-child(2) {
            width: 25%;
          }
          &:nth-child(3) {
            width: 20%;
          }
          &:nth-child(4) {
            width: 14%;
          }
          &:nth-child(5),
          &:nth-child(6) {
            width: 8%;
          }
        }
      }
      tbody {
        td {
          padding: 15px 30px;
          &:nth-child(1) {
            color: $sort-green !important;
            text-decoration: underline;
            cursor: pointer !important;
          }
          &:nth-child(5) {
            cursor: pointer !important;
          }
        }
      }
    }
    &.upload-doc-table {
      thead{
        th:first-child{
          width:80%;
        }
        th{
          width:10%;
        }
      }
      tbody {
        td:first-child {
          text-decoration: none;
          color: #77bf4b!important;
        }
      }
    }
  }
  .dashboard-capital-table{
    box-shadow: 0px 16px 24px rgb(0 0 0 / 12%);
      th{
        font-size: 12px;
        padding: 15px 10px;
        border-bottom: 1px solid $sort-green;
        pointer-events: none;
        &:first-child{
          width:20%;
          border-top-left-radius: 5px;
        }
        &:nth-child(2){
          width:20%;
        }
        &:nth-child(3){
          width:30%;
        }
        &:nth-child(4){
          width:30%;
          border-top-right-radius: 5px;
        }
      }
      tbody{
        td{
          font-size: 12px;
        padding: 10px;
        border-bottom: 1px solid #d1cece;
        pointer-events: none;
        &:first-child{
          color:rgba(0, 0, 0, 0.5) !important;
          text-decoration: none !important;
        }
        }
      }
    
  }
  &.audit-data-table {
    thead {
      th {
        width: 10% !important;
        padding: 10px 8px !important;
        word-wrap: break-word;
        &:first-child {
          width: 15% !important;
        }
        &:nth-child(7),
        &:nth-child(8) {
          width: 8% !important;
        }
        &:last-child {
          width: 4% !important;
        }
      }
    }
    tbody {
      td {
        padding: 10px 8px !important;
      }
    }
  }
  
  &.opp-table {
    table {
      th {
        vertical-align: middle;
      }
    }
  }
  .client-reports-table {
      th {
        width: auto !important;
      }
      th:last-child {
        width: 20px !important;
      }
      tbody {
        tr {
          td:last-child {
            text-align: right;
            cursor: pointer !important;
          }
          td:first-child{
            color: #77bf4b!important;
          }
        }
      }
  }
  
  .table-first-row-link{
    tbody{
      td{
      &:first-child{
        color:$sort-green !important;
      }
      }
    }
  }
  .table-nw-audit-table{
    thead{
      tr > th{
        width: 20px !important;
        padding: 15px !important;
      }
    }
    td:nth-child(7), td:nth-child(8) {
      max-width: 100px;
    }
    td{
      padding: 15px !important;
      &:first-child{
        text-decoration: none !important;
        color: rgba(0, 0, 0, 0.5) !important;
      }
    }
  }
}
.breadcrumb-item {
  a {
    color: $black;
  }
  &.active a {
    color: $sort-green;
  }
}
.page-back {
  margin: 20px 0;
  cursor: pointer;
  span {
    font-size: 18px;
    font-weight: 600;
    color: $sort-green;
    margin: 0 5px;
    &.username {
      color: $black;
      text-transform: capitalize;
    }
  }
}
@media only screen and (min-width: 992px) and (orientation: landscape) {
  .navbar-header.header-large-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    height: 60px;
    padding: 20px;
    .dropdown {
      .dropdown-menu {
        padding: 0;
      }
    }
  }
  .navbar-header.header-mobile-view {
    display: none !important;
  }
}
/* Medium devices (landscape tablets, 768px and down) */
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .navbar-header.header-large-view {
    height: 50px !important;
    .dropdown {
      .dropdown-menu {
        padding: 0;
      }
    }
  }
  .navbar-header.header-mobile-view {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .navbar-header.header-mobile-view {
    display: block !important;
    height: 50px !important;
    .dropdown {
      .dropdown-menu {
        padding: 0;
      }
    }
    .mobile-header-items {
      padding: 10px;
      text-align: center;
      &.logo-user {
        text-align: right !important;
        img {
          width: 30px !important;
        }
      }
    }
  }
  .navbar-header.header-large-view {
    display: none !important;
  }
  .pagination {
    .page-item {
      .btn {
        font-size: 1rem;
      }
    }
  }
}

// samsung galaxy S20
@media only screen and (min-width: 360px) and (max-width: 767px) {
  .navbar-header.header-large-view {
    display: none !important;
  }
  .navbar-header.header-mobile-view {
    display: block !important;
    height: 50px !important;
    .dropdown {
      .dropdown-menu {
        padding: 0;
      }
    }
    .mobile-header-items {
      padding: 10px;
      text-align: center;
      &.logo-user {
        text-align: right !important;
        img {
          width: 30px !important;
        }
      }
    }
  }
  .navbar-header.header-large-view {
    display: none !important;
  }
  .pagination {
    .page-item {
      .btn {
        font-size: 1rem;
      }
    }
  }
}
//iphone 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .navbar-header.header-mobile-view {
    display: block !important;
    height: 50px !important;
    .dropdown {
      .dropdown-menu {
        padding: 0;
      }
    }
    .mobile-header-items {
      padding: 10px;
      text-align: center;
      &.logo-user {
        text-align: right !important;
        img {
          width: 30px !important;
        }
      }
    }
  }
  .navbar-header.header-large-view {
    display: none !important;
  }
  .pagination {
    .page-item {
      .btn {
        font-size: 1rem;
      }
    }
  }
}

.table-wrapper {
  height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}

//forms
select.form-control {
  background-color: #f9f9f9;
  padding: 12px 15px;
}
.form-control {
  background-color: #f9f9f9;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #f9f9f9;
  border-color: #c7c9cc;
  opacity: 1;
  color: #c7c9cc;
}
.form-control.form-white {
  background-color: #fff !important;
}

.search-content {
  &.input-group:focus-visible {
    border: 1px solid $sort-green;
  }
  .sort-form-input {
    // border-right:none;
    padding: 10px 15px;
    &.errorField {
      // border-right:none;
    }
    input:-internal-autofill-selected {
      background-color: #f9f9f9;
    }
  }
  .input-group-text {
    &.errorField {
      border-top: 1px solid $error;
      border-right: 1px solid $error;
      border-bottom: 1px solid $error;
      border-left: none;
      border-top-right-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
    }
    &:focus {
      border-top: 1px solid $sort-green;
      border-right: 1px solid $sort-green;
      border-bottom: 1px solid $sort-green;
      border-left: none;
      border-top-right-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
    }
  }
}

.input-group-text {
  color: #abaaaa;
  background-color: #f9f9f9;
  border-left: none;
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.1;
}

.sort-badge {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-color: $sort-gray;
  /* font-size: .75em; */
  font-weight: 700;
  /* line-height: 1; */
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 50rem !important;
  &.badge-active {
    background-color: $sort-green;
  }
  &.badge-inactive {
    background-color: $sort-gray;
  }
  &.badge-blocked {
    background-color: red;
  }
}

.inline-form-error {
  display: none;
  &.display-error {
    display: block;
    color: $error;
  }
}

.form-select {
  background-color: $primary-gray;
  padding: 11px 15px;
  font-size: $h6-font-size;
  color: $sort-gray;
  text-transform: capitalize;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:focus {
    border-color: $sort-green;
    outline: 0;
    box-shadow: none;
  }
  &.display-error {
    border: 1px solid $error;
    color: $error;
    option {
      color: $sort-gray;
      padding: 20px;
    }
  }
}

.spinner-border {
  border: 0.25em solid $white;
  border-right-color: $sort-green;
  &.page-spinner {
    border: 0.25em solid $white;
    border-right-color: $sort-green;
    width: 5rem;
    height: 5rem;
    margin-top: 10%;
    &.modal-spinner {
      margin: 0 !important;
    }
  }
}

.btn-primary:disabled,
.btn-primary.disabled {
  background-color: $input-btn-primary-disabled-bg;
  border: 1px solid $input-btn-primary-disabled-bg;
}

//alerts
.alert {
  font-size: 15px;
  padding: 10px;
  &.alert-danger {
    color: #f03738;
    background: linear-gradient(0deg, #ffcdcd, #ffcdcd), #3e66fb;
    border-radius: 8px;
    border-color: #f03738;
  }
  &.alert-success {
    color: #63a837;
    background: #e4f1d7;
    border-radius: 8px;
    border-color: #63a837;
  }
}

//react-select
.css-g1d714-ValueContainer {
  padding: 10px 15px !important;
}
.css-1s2u09g-control {
  background-color: #f9f9f9 !important;
  box-shadow: none !important;
  min-height: 50px !important;
  font-size: 15px !important;
  &:hover {
    border-color: #ced4da !important;
  }
}
.css-1pahdxg-control {
  background-color: #f9f9f9 !important;
  box-shadow: none !important;
  min-height: 50px !important;
  font-size: 15px !important;
  border-color: $sort-green !important;
  &:focus {
    border-color: $sort-green !important;
  }
  &:hover {
    border-color: $sort-green !important;
  }
}
.css-1rhbuit-multiValue {
  background-color: #daebcc !important;
  border-radius: 20px !important;
  color: #77bf4b !important;
  .css-12jo7m5 {
    color: #77bf4b !important;
  }
  .css-xb97g8 {
    background-color: #daebcc !important;
    color: #77bf4b !important;
    border-radius: 20px !important;
  }
}

.css-6sex7w-control {
  background-color: hsl(0deg 0% 98%) !important;
  font-size: 15px !important;
  color: #55636b !important;
}
.css-1ozuhuy-control {
  border-color: #ced4da !important;
  background-color: hsl(0deg 0% 98%) !important;
  box-shadow: none !important;
  &:hover {
    border-color: $sort-green !important;
  }
}
.d-inline-block {
  &.view-filter-section {
    &.errorField {
      .css-1s2u09g-control,
      .css-6sex7w-control {
        border: 1px solid #f03738 !important;
        color: #f03738 !important;
      }
      .errorImg {
        position: absolute;
        right: -10px;
        top: 40px;
      }
    }
    .css-1s2u09g-control {
      min-height: 50px !important;
      .css-g1d714-ValueContainer {
        padding: 0 15px !important;
      }
    }
    .css-1pahdxg-control {
      min-height: 40px !important;
    }
    &.project-view{
      .css-1s2u09g-control {
      min-height: 44px !important;
    }
    }
  }
  &.select-wrapper {
    &.errorField {
      .css-1s2u09g-control,
      .css-6sex7w-control {
        border: 1px solid #f03738 !important;
        color: #f03738 !important;
      }
      .errorImg {
        position: absolute;
        right: -10px;
        top: 40px;
      }
    }
    &.subsidiary-select {
      &.disabled {
        pointer-events: none !important;
      }
    }
  }
}
.checkmark {
  position: absolute !important;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: transparent !important ;
  border: 1px solid white !important;
}

.container-checkbox {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  border: 0.791667px solid #ffffff;
  box-sizing: border-box;
  border-radius: 3.95833px;
}

/* On mouse-over, add a grey background color */
.container-checkbox input ~ .checkmark {
  background-color: #ccc;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
  left: 4px;
  top: 0px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.reset-expansion-style {
  text-decoration: none !important;
}

// Audit Account

.header-audit {
  height: 43px;
  background: linear-gradient(0deg, #eff6e8, #eff6e8), #3e66fb;
  margin: 20px 0;
  cursor: pointer;
  span {
    font-size: 18px;
    font-weight: 600;
    color: $sort-green;
    margin: 0 5px;
    &.username {
      color: $black;
      text-transform: capitalize;
    }
  }
  &.client-view-report {
    margin: 0 !important;
    padding: 20px 0;
    height: auto;
    background: #daebcc;
  }
}
// samsung galaxy S20
@media only screen and (min-width: 360px) and (max-width: 767px) {
  .header-audit {
    &.client-view-report {
      margin: 0 15px !important;
    }
  }
}
//iphone 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .header-audit {
    &.client-view-report {
      margin: 0 15px !important;
    }
  }
}

.update-tab-section {
  ul.nav-tabs {
    font-size: 16px;
    border-bottom: 5px solid #77bf4b;
    li {
      &:first-child {
        margin-left: 25%;
      }
      button {
        height: 65px;
        color: #55636b !important;
        width: 150px;
        &.nav-link.active {
          background-color: #77bf4b;
          color: #fff !important;
          border: 1px solid #77bf4b;
        }
        &:hover,
        &:focus {
          border-color: #77bf4b;
          isolation: isolate;
        }
      }
    }
  }
  &.client-view {
    ul.nav-tabs {
      li {
        &:first-child {
          margin-left: 0 !important;
          button {
            width: 100px;
          }
        }
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6) {
          button {
            width: 150px;
          }
        }
        &:nth-child(7) {
          button {
            width: 210px;
          }
        }
      }
    }
  }
  &.admin-view {
    ul.nav-tabs {
      li {
        &:first-child {
          button {
            width: auto !important;
          }
        }
        &:nth-child(2) {
          button {
            width: auto !important;
          }
        }
      }
    }
  }
}

.audit-page-back {
  cursor: pointer;
  span {
    font-size: 20px;
    font-weight: bold;
    line-height: 140%;
    color: $sort-green;
    margin: 0 5px;
    &.username {
      color: #606060;
      text-transform: capitalize;
    }
  }
}

.wrapper-class.rdw-editor-wrapper {
  border-radius: 6.35589px;
  border: 0.79px solid #9a9fa6;
  min-height: 175px;
}

.disable-editor.rdw-editor-wrapper {
  border-radius: 6.35589px;
  border: 0.79px solid #9a9fa6;
  min-height: 175px;
  color: #e7e8eb;
}

.editor-class {
  background: #f9f9f9;
  border-radius: 6.35589px;
  padding: 0 20px;
}

.main-toolbar {
  background: #e3e4e8;
  border-radius: 6.35589px 6.35589px 0px 0px;
}

#client-view-report-tabs-tabpane-6,
#client-view-report-tabs-tabpane-5 {
  .wrapper-class.rdw-editor-wrapper {
    width: 75%;
    margin: 0 auto;
    background-color: #fff;
    border: none;
    .rdw-editor-toolbar {
      &.main-toolbar {
        display: none;
      }
    }
    .rdw-editor-main {
      background-color: #fff;
    }
  }
}
.custom-editor-title {
  width: 75%;
  margin: 0 auto;
  background-color: $sort-green;
  padding: 10px 20px;
  color: #fff;
  font-weight: 700;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 16px;
}
@media only screen and (min-width: 360px) and (max-width: 385px) and (-webkit-min-device-pixel-ratio: 4) {
  .custom-editor-title {
    width: 100% !important;
  }
  #client-view-report-tabs-tabpane-6,
  #client-view-report-tabs-tabpane-5 {
    .wrapper-class.rdw-editor-wrapper {
      width: 100% !important;
    }
  }
}
//iphone 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .custom-editor-title {
    width: 100% !important;
  }
  #client-view-report-tabs-tabpane-6,
  #client-view-report-tabs-tabpane-5 {
    .wrapper-class.rdw-editor-wrapper {
      width: 100% !important;
    }
  }
}

.toolbar-style1 {
  background: #e3e4e8 !important;
  border: none !important;
}

.image-style {
  display: none;
}

.rdw-option-wrapper {
  box-shadow: none !important;
  &:active {
    box-shadow: none !important;
  }
  &:focus {
    box-shadow: none !important;
  }
  &:hover {
    box-shadow: none !important;
    background: lightblue !important;
  }
}

.rdw-option-active {
  background: lightblue !important;
}

.rdw-dropdown-wrapper {
  box-shadow: none !important;
  &:active {
    box-shadow: none !important;
  }
  &:focus {
    box-shadow: none !important;
  }
  &:hover {
    box-shadow: none !important;
    background: lightblue !important;
  }
}

.rdw-colorpicker-modal {
  height: max-content !important;
}

.rdw-dropdown-optionwrapper {
  max-height: max-content !important;
  overflow: hidden !important;
}

.rdw-colorpicker-modal-options {
  overflow: hidden !important;
}

.rdw-center-aligned-block * {
  text-align: center !important;
}

.public-DraftStyleDefault-ltr {
  text-align: inherit !important;
}

.rdw-image-modal-upload-option-image-preview {
  max-width: 200px;
  max-height: 50px;
}

//text editor
.rdw-link-modal {
  height: 220px;
}

// Opportunity
.opportSumm-header {
  font-family: "focoBold";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: #55636b;
}

// Report Settings
.report-settings-tab {
  ul.nav-tabs {
    border-bottom: 5px solid #77bf4b;
    li {
      &:first-child {
        margin-left: 25%;
      }
      button {
        color: #55636b !important;
        width: 200px;
        &.nav-link.active {
          background-color: #77bf4b;
          color: #fff !important;
          border: 1px solid #77bf4b;
        }
        &:hover,
        &:focus {
          border-color: #77bf4b;
          isolation: isolate;
        }
      }
    }
  }
}

//override video player styles
.video-react .video-react-big-play-button {
  display: none !important;
}
.video-react .video-react-control-bar {
  display: -webkit-flex !important;
  display: flex !important;
  visibility: visible;
}

.client-view-report-list {
  .search-content-wrapper {
    position: relative;
    .search-content {
      position: relative;
      width: 85%;
    }
  }
}
@media (min-width: 1025px) {
  .update-tab-section {
    ul.nav-tabs {
      overflow: hidden;
    }
  }
}
/* Medium devices (landscape tablets, 768px and down) */
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .client-view-report-list {
    .search-content-wrapper {
      position: relative;
      .search-content {
        position: relative;
        width: 85%;
      }
    }
  }
  .update-tab-section {
    ul.nav-tabs {
      border-bottom: 5px solid #77bf4b;
      overflow-x: scroll;
      white-space: nowrap;
      overflow-y: hidden;
      display: inline-block;
      li {
        display: inline-block;
        &:first-child {
          margin-left: 25%;
        }
        button {
          color: #55636b !important;
          width: 150px;
          &.nav-link.active {
            background-color: #77bf4b;
            color: #fff !important;
            border: 1px solid #77bf4b;
          }
          &:hover,
          &:focus {
            border-color: #77bf4b;
            isolation: isolate;
          }
        }
      }
    }
  }
}
/* Medium devices (landscape tablets, 768px and down) */
@media only screen and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .client-view-report-list {
    .search-content-wrapper {
      position: relative;
      .search-content {
        position: relative;
        width: 65%;
      }
    }
  }
  .client-view .guide-tab {
    margin: 0 !important;
  }
}

// samsung galaxy S20
@media only screen and (min-width: 360px) and (max-width: 767px) {
  .client-view-report-list {
    .search-content-wrapper {
      position: relative;
      .search-content {
        position: relative;
        width: 70%;
        .sort-form-input {
          font-size: 14px;
        }
      }
    }
  }
  .update-tab-section.client-view {
    padding: 0 10px;
    ul.nav-tabs {
      border-bottom: 5px solid #77bf4b;
      overflow-x: scroll;
      white-space: nowrap;
      overflow-y: hidden;
      display: inline-block;
      li {
        display: inline-block;
        &:first-child {
          margin-left: 25%;
        }
        button {
          color: #55636b !important;
          width: 150px;
          &.nav-link.active {
            background-color: #77bf4b;
            color: #fff !important;
            border: 1px solid #77bf4b;
          }
          &:hover,
          &:focus {
            border-color: #77bf4b;
            isolation: isolate;
          }
        }
      }
    }
  }
}

//iphone 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .client-view-report-list {
    .search-content-wrapper {
      position: relative;
      .search-content {
        position: relative;
        width: 65%;
        .sort-form-input {
          font-size: 14px;
        }
      }
    }
  }
  .update-tab-section {
    ul.nav-tabs {
      border-bottom: 5px solid #77bf4b;
      overflow-x: scroll;
      white-space: nowrap;
      overflow-y: hidden;
      display: inline-block;
      li {
        display: inline-block;
        &:first-child {
          margin-left: 25%;
        }
        button {
          color: #55636b !important;
          width: 150px;
          &.nav-link.active {
            background-color: #77bf4b;
            color: #fff !important;
            border: 1px solid #77bf4b;
          }
          &:hover,
          &:focus {
            border-color: #77bf4b;
            isolation: isolate;
          }
        }
      }
    }
  }
}

.rdw-link-decorator-wrapper {
  span {
    color: #3e66fb;
    cursor: pointer;
    text-decoration: underline;
  }
}

.report-accordion.collapse:not(.show) {
  display: block !important;
}

// Dashboard Settings

.dashboard-settings-tab {
  ul.nav-tabs {
    border-bottom: 5px solid #77bf4b;
    li {
      &:first-child {
        margin-left: 35%;
      }

      #noanim-tab-example-tab-universal {
        padding-top: 20px;
        width: 150px;
        padding-bottom: 15px;
      }
      #noanim-tab-example-tab-clientDashboard {
        width: 250px;
      }
      button {
        color: #55636b !important;
        // width: 200px;
        &.nav-link.active {
          background-color: #77bf4b;
          color: #fff !important;
          border: 1px solid #77bf4b;
        }
        &:hover,
        &:focus {
          border-color: #77bf4b;
          isolation: isolate;
        }
      }
    }
  }
}
.view-staff-seltc-status, .search-staff, .update-staff-role, .search-opp-field{
  padding: 14px 15px !important;
}

.custom-audit-note{
  .custom-editor-title{
    display: none ;

  }
  .wrapper-class.rdw-editor-wrapper{
    min-height: auto;
    border: 0.79px solid #e6e8eb;
    .rdw-editor-toolbar.main-toolbar {
      display: none;
      visibility: hidden!important;
    }
  }
}

.audit-document-row-column {
  padding: 10px;
  display: inline-block;
  width: 50%;
  text-align: right;
  font-size: 14px !important;
}

.img-modal-header{
  border-bottom: none !important;
  color: #77bf4b;
}

.react-datepicker-wrapper {
  width: 100%;
}