// Client Projects

.btn-project-min-width {
  min-width: 180px;
}

.project-search-btn{
  width: 40px;
}

.search-client{
  margin-top: 0;
  right: 0;
  top: 0, 
}

// @media only screen and (min-device-width: 800px) and (max-device-width: 1280px) and (-webkit-min-device-pixel-ratio: 2) {
//   .btn-project-min-width {
//     min-width: 150px;
//   }
//   .client-project-table {
//     thead {
//       background-color: $white;
//       tr {
//         th {
//           font-size: 10px;
//         }
//       }
//     }
//   }
// }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
}

@media only screen and (min-width: 360px) and (max-width: 767px) {
  .btn-project-min-width {
    min-width: 90px; 
    margin-left: 40px;   
  }
  .category-client {
    margin-top: 10px;
  }

  .project-search-btn{
    width: 30px !important;
  }
  .search-client{
    margin-top: 5px;
    right: -40px !important;    
  }

  .category-client-checkbox {
    .form-check-input[type="checkbox"] {
      width: 15px;
      height: 15px;
    }
  }
  .client-project-search {
    min-width: 240px;
    font-size: 13px;
  }
  // .client-project-table {
  //   display: none;
  // }
  .client-project-table-mobile {
    display: block !important;
    table {
      border-color: transparent;
      > :not(:first-child) {
        border-top: none;
      }
    }
  }
  .client-project-expand-table-mobile {
    border-color: transparent;

    table{
      .client-project-report-row {
        .card {
          background-color: #f9f9f9;
          color: rgba(0, 0, 0, 0.5);
        }
        .card-header {
          background-color: #f9f9f9;
          border-left: 0.5px solid #e3e4e8;
          border-right: 0.5px solid #e3e4e8;
        }
        .card-body{
          border: 0.5px solid #e3e4e8;          
        }
      }
    }

    thead {
      background-color: $white;
      tr {
        th {
          color: $sort-green;
          padding: 15px 0px 15px 15px;         
        }
      }
    }

    tbody {
      tr {
        td {
          color: rgba(0, 0, 0, 0.5);
          padding: 5px 0px 5px 15px;    
          text-transform: capitalize;      
        }
      }
    }
  }

  .row-client-project{
    border-left: 0.5px solid #e3e4e8;
    border-right: 0.5px solid #e3e4e8;
  }

  .project-third-header{
    border-left: 0.5px solid #e3e4e8;
    border-right: 0.5px solid #e3e4e8;
  }

  
}

.client-project-table-mobile {
  display: none;
}

