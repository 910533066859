// Sidebar
$sidebar-bg: #ffffff;
$sidebar-menu-item-color: #ffffff;
$sidebar-menu-sub-item-color: #ffffff;
$sidebar-menu-item-icon-color: #ffffff;
$sidebar-menu-item-hover-color: #ffffff;
$sidebar-menu-item-active-color: #ffffff;
$sidebar-width:  250px;
$sidebar-collapsed-width:  70px;
$sidebar-width-sm:  160px;
$layout-collapsed-min-height: 1760px;

// Topbar
$header-height: 70px;
$header-bg: #ffffff;
$header-item-color: #ffffff;

// Topbar Search
$topbar-search-bg: #ffffff;

// Footer
$footer-height: 60px;
$footer-bg: #ffffff;
$footer-color: #ffffff;

// Sidebar
$rightbar-width:  280px;

// Display
$display-none: none;
$display-block: block;

// Brand 
$navbar-brand-box-width: 250px;

// Font Weight
$fw-medium: 500;
$font-weight-semibold: 600;

// Color system

$white:    #fff;
$black:    #000;

$sort-green:#77BF4B;
$sort-green-shade1:#B9DA9E;
$sort-green-shade2:#DAEBCC;
$sort-green-shade3:#E4F1D7;
$sort-green-shade4:#EFF6E8;

$sort-green-dark:#63A837;

$sort-gray:#55636B;
$sort-gray-shade1:#9A9FA6;
$sort-gray-shade2:#C7C9CC;
$sort-gray-shade3:#E3E4E8;
$sort-gray-shade4:#E7E8EB;

$sort-secondary-color1:#29A4A7;
$sort-secondary-color1-shade:#CBE3E4;
$sort-secondary-color2:#4088C5;
$sort-secondary-color2-shade:#CBD8EE;
$sort-secondary-color3:#1786BE;
$sort-secondary-color3-shade:#C6D7EC;
$sort-secondary-color4:#734D90;
$sort-secondary-color4-shade:#CFC5DC;


$primary1:       $sort-green;
$primary2:       $sort-gray;
$primary-gray:   #F9F9F9;
$success:       $sort-green;
$warning:       #F3BB1C;
$error:        #F03738;

$sort-text-primary:$sort-gray;
$sort-text-primary-shade1:$sort-gray-shade1;

// Body

$body-bg:                   $white;
$body-bg-shade1:            $sort-gray-shade2;
$body-bg-shade2:            $sort-gray-shade3;


// Links

$link-color:                              $primary;
$link-decoration:                         none;
$link-shade-percentage:                   20%;
$link-hover-decoration:                   underline;


// Typography

// $font-size-base effects the font size of the body text
$font-size-base:              0.8125rem; //13px

$font-weight-lighter:         lighter;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-bold:            600;
$font-weight-bolder:          bolder;

$font-weight-base:            $font-weight-normal;
$line-height-base:            1.5;
$line-height-sm:              1.25;
$line-height-lg:              2;

$h1-font-size:                2.8125rem;
$h2-font-size:                2.1875rem;
$h3-font-size:                1.875rem;
$h4-font-size:                1.25rem;
$h5-font-size:                0.9375rem;
$h6-font-size:                0.8125rem;

$paragraph1: 0.9375rem;
$paragraph2: 0.8125rem;

$character:0.6875rem;


// Buttons
$input-btn-primary-bg: $sort-green;
$input-btn-primary-color: $white;
$input-btn-primary-border-color: $sort-green;
$input-btn-primary-border-radius: 5px;
$input-btn-primary-moveOver-bg: $sort-green-shade1;
$input-btn-primary-clicked-bg: #63A837;
$input-btn-primary-disabled-bg:  $sort-gray-shade2;

$input-btn-secondary-bg: $white;
$input-btn-secondary-color: $sort-green;
$input-btn-secondary-border-color: $sort-green;
$input-btn-secondary-border-radius: 5px;
$input-btn-secondary-moveOver-bg: $primary-gray;
$input-btn-secondary-clicked-bg: $sort-gray-shade3;


$input-btn-md-padding-y:      10px;
$input-btn-md-padding-x:      32px;
$input-btn-md-font-size:         $h5-font-size;

$input-btn-sm-padding-y:      8px;
$input-btn-sm-padding-x:      24px;
$input-btn-sm-font-size:         $h5-font-size;


//forms
$form-text-margin-top:                  8px;
$form-text-font-size:                   $h5-font-size;
$form-text-font-style:                  normal;
$form-text-font-weight:                 400;
$form-text-color:                       $sort-gray;
$form-text-placeholder-color:           $sort-gray-shade1;
$form-text-border-color:                #55636B;
$form-text-border-radius:               5px;

$form-label-margin-bottom:              8px;
$form-label-font-size:                  $h6-font-size;
$form-label-font-style:                 normal;
$form-label-font-weight:                400;
$form-label-color:                      $black;

$form-hint-color:                      $sort-gray;
$form-hint-font-style:                 'focoLight';
$form-hint-font-size:                   0.6875rem;
$form-hint-color:                      $sort-gray;

$form-error-color:                      $error;
$form-error-border-color:               $error;
$form-error-font-style:                 'focoRegular';
$form-error-font-size:                   $h6-font-size;
$form-error-top-margin:                 8px;


$input-padding-y:                       15px;
$input-padding-x:                       15px;
$input-line-height:                     18.95px;

$input-bg:                              $white;
$input-mouse-over-bg:                   $primary-gray;
$input-disabled-bg:                     $primary-gray;
$input-clcked-border-color:             $sort-green;
$input-disabled-border-color:           $primary-gray;

