// Client Carbon RoadMap

.carbon-title {
  font-family: "focoBold";
  font-style: normal;
  font-size: 20px;
  line-height: 140%;
  color: #55636b;
  margin-bottom: 15px;
}

.carbon-paragraph {
  font-family: "focoLight";
  font-style: normal;
  font-size: 15px;
  line-height: 140%;
  color: #55636b;
}

@media only screen and (min-width: 360px) and (max-width: 767px) {
  .carbon-title {
    font-family: "focoBold";
    font-style: normal;
    font-size: 14px;
    line-height: 140%;
    color: #55636b;
    margin-bottom: 15px;
  }
  .carbon-paragraph {
    font-family: "focoLight";
    font-style: normal;
    font-size: 13px;
    line-height: 140%;
    color: #55636b;
  }
}
